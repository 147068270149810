export const data = [
  {
    name: "2020",
    data: [10, 41, 35, 51, 49, 62, 69, 91, 80, 90, 60, 50],
  },
  {
    name: "2021",
    data: [20, 51, 45, 61, 59, 72, 79, 101, 90, 100, 70, 60],
  },
  {
    name: "2022",
    data: [30, 61, 55, 71, 69, 82, 89, 111, 100, 110, 80, 70],
  },
];

export const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
