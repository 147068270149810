import React, { useEffect, useState } from "react";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { getClientRateCount } from "../../services/clientHistoryService";
import LineChart from "../charts/LineChart";

const RateWidget = ({ rate }) => {
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getWidgetData();
  }, []);

  const getWidgetData = async () => {
    try {
      const { data } = await getClientRateCount(rate.id);

      const total = data.users;
      const percentage = data.percentage;
      const status = data.status;
      setValue({
        id: rate.id,
        title: rate.title,
        total,
        percentage,
        status,
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  if (loading)
    return <div className="shadow p-2 rounded-md overflow-hidden h-36"></div>;

  return (
    <div className="shadow p-2 rounded-md overflow-hidden  ">
      {/* Top Container */}
      <div className="flex justify-between">
        <h3 className="text-base font-medium ">{value.title}</h3>
      </div>

      {/* Bottom Container */}
      <div className="flex relative">
        {/* Left */}
        <div className="flex flex-col mt-4 w-3/5">
          <h3 className="text-4xl font-semibold">{value.total}</h3>
          <div className="flex mt-2 items-center">
            {value.status === "up" ? (
              <BiUpArrowAlt style={{ color: "#12B76A" }} />
            ) : (
              <BiDownArrowAlt style={{ color: "#F04438" }} />
            )}
            <p
              className={`ml-1 text-sm font-medium `}
              style={{ color: value.status === "up" ? "#12B76A" : "#B42318" }}
            >
              {value.percentage}%
            </p>
            <p className="ml-2 text-gray-500 text-sm font-medium">
              vs último mes
            </p>
          </div>
        </div>
        {/* Right */}
        <div className="flex-1 h-full w-2/5">
          <LineChart status={value.status} />
        </div>
      </div>
      {/* Bottom Container End */}
    </div>
  );
};

export default RateWidget;
