import React from "react";

const AppButton = ({
  title,
  extraClasses,
  Icon,
  color = "white",
  bgColor = "#1D2939",
  type = "button",
  ...rest
}) => {
  return (
    <div className={extraClasses}>
      <button
        className="py-2.5 px-4 rounded-lg shadow disabled:cursor-not-allowed flex items-center gap-2 justify-center"
        style={{
          backgroundColor: rest.disabled ? "#444" : bgColor,
          color: color,
          width: "100%",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          border: "1px solid #D0D5DD",
        }}
        {...rest}
        type={type}
      >
        {Icon && (
          <span className="text-lg">
            <Icon />
          </span>
        )}
        <span className="text-md font-medium">{title}</span>
      </button>
    </div>
  );
};

export default AppButton;
