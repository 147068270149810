import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import { AiOutlineFile } from "react-icons/ai";
import RoundedIcon from "../../components/form/RoundedIcon";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { TabList } from "@mui/lab";
import * as Yup from "yup";
import FormikInput from "../../components/form/FormikInput";
import AppButton from "../../components/common/AppButton";
import { FiSearch } from "react-icons/fi";
import InputField from "../../components/common/InputField";
import { Form, Formik } from "formik";
import FormikDatePicker from "../../components/form/FormikDatePicker";
import { BsLayers, BsTelephone } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { AiOutlineMail } from "react-icons/ai";
import { FiCreditCard } from "react-icons/fi";
import ReceiptTable from "../../components/common/tables/ReceiptTable";
import FormAlert from "../../components/form/FormAlert";
import { addRemittance } from "../../services/billingService";
import { useSnackbar } from "notistack";
import { IoWarningOutline } from "react-icons/io5";
import { userRows } from "../../assets/data/receiptTableSource";
import { getClients } from "../../services/clientService";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LinearProgress } from "@mui/material";

const NewConsignment = () => {
  const [value, setValue] = React.useState("1");
  const [receipts, setReceipts] = useState(userRows);
  const [filteredReceipts, setFilteredReceipts] = useState([]);
  const [remittance, setRemittance] = useState({});
  const [alertVisible, setAlertVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const validateFirst = Yup.object({
    nameOfRemittance: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    dateOfCharge: Yup.date().required("Date is required").nullable(),
    position: Yup.string().required("Position is required"),
    businessName: Yup.string().required("Business name is required"),
    cif: Yup.string().required("CIF is required"),
    direction: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Direction is required"),
    email: Yup.string().email().required("Email is required"),
    phoneNo: Yup.string()
      .min(9, "Must be 9 digits or more")
      .required("Telephone Number is required"),
    accountNo: Yup.string().required("Account Number is required"),
    bic: Yup.string().required("BIC is required"),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const accept = async () => {
    setLoading(true);
    try {
      await addRemittance(remittance);
      // console.log("Remittance Added");
      // enqueueSnackbar("Remittance Added Successfully!", { variant: "success" });
      localStorage.setItem("success", "Remesa añadida con éxito");

      navigate("/billing", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.response.data, { variant: "error" });
    }
    setLoading(false);
  };

  const reject = () => {
    console.log("Reject");
  };

  const getAllReceipts = async () => {
    setLoading(true);
    try {
      const { data } = await getClients();
      // console.log(data);
      let activeUsers = data.filter((user) => user.status === "Activo");
      let filteredUsers = activeUsers.filter(
        (user) => moment(user.enrollmentDate).date() < 20 || user.new === false
      );
      activeUsers = filteredUsers.map((receipt) => ({
        id: receipt._id,
        info: {
          name: receipt.firstName + " " + receipt.lastName,
          included: receipt.included,
        },
        status: "Completo",
        method: "..." + receipt.accountNo.substr(receipt.accountNo.length - 4),
        amount: receipt.package.monthlyPrice + "€",
      }));

      setReceipts(activeUsers);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    let r = receipts.filter((receipt) => {
      let name = receipt.info.name.toLowerCase();
      return name.includes(e.target.value.toLowerCase());
    });

    setFilteredReceipts(r);
  };

  useEffect(() => {
    getAllReceipts();

    return () => {
      setReceipts([]);
      setFilteredReceipts([]);
    };
  }, []);

  useEffect(() => {
    // getAllReceipts();
    setFilteredReceipts(receipts);

    return () => {};
  }, [receipts]);

  return (
    <Layout activeItem={4}>
      {alertVisible && (
        <FormAlert
          agree={accept}
          disagree={reject}
          open={alertVisible}
          setOpen={setAlertVisible}
          message="Do you want to save the remittance?"
          acceptTitle="Salvar"
          rejectTitle="Cancelar"
          IconComponent={IoWarningOutline}
          darkColor="#FEF0C7"
          lightColor="#FFFAEB"
          iconColor="#DC6803"
        />
      )}

      {loading && <LinearProgress color="secondary" />}

      {/* Title Container */}
      <div className="flex items-center">
        <RoundedIcon Icon={AiOutlineFile} />
        <h1 className="text-3xl ml-2 font-medium">Nueva remesa</h1>
      </div>

      {/* Tabs Container */}
      <div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              // onChange={handleChange}
              aria-label="lab API tabs example"
              className="text-purple-500 border-bottom-purple-500"
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#A855F7",
                },
              }}
            >
              <Tab label="Datos de la remesa" value="1" />
              <Tab label="Listado de recibos" value="2" />
            </TabList>
          </Box>
          {/* First Step */}
          <TabPanel value="1">
            <Formik
              initialValues={{
                nameOfRemittance: remittance.nameOfRemittance || "",
                dateOfCharge: remittance.dateOfCharge || null,
                position: remittance.position || "",
                businessName: "Cris4life Woman Center S.L.",
                cif: "B76348499",
                direction: "Calle Cardiff 1, 28232, Las Rozas",
                email: "info@cris4life.es",
                phoneNo: "606550330",
                accountNo: "ES8421005620140200230056",
                bic: "CAIXESBBXXX",
              }}
              validationSchema={validateFirst}
              onSubmit={(values) => {
                setRemittance(values);
                setValue("2");
                // console.log(profileImage);
                // handleSubmit(values);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    {/* Heading */}
                    <div className="flex flex-col-reverse lg:flex-row gap-4 justify-between">
                      <div>
                        <h3 className="text-lg font-medium">
                          Información sobre la remesa
                        </h3>
                        <p className="text-sm text-gray-500">
                          Añade todos los datos relacionados con la remesa
                        </p>
                      </div>
                      <div className="flex gap-6">
                        <AppButton
                          title="Cancelar"
                          color="black"
                          bgColor="white"
                        />
                        <AppButton
                          title="Guardar y continuar"
                          type="submit"
                          disabled={!formik.isValid}
                        />
                      </div>
                    </div>
                    <hr className="my-5 " />

                    {/* Name of the remittance */}
                    <div className="flex flex-col lg:flex-row gap-4">
                      <div className="w-full lg:w-1/3">
                        <label className="text-sm font-medium">
                          Nombre de la remesa
                        </label>
                        <p className="text-sm text-gray-500">
                          El nombre que aparecerá en tu panel
                        </p>
                      </div>
                      <div className="flex-1">
                        <FormikInput
                          name="nameOfRemittance"
                          placeholder="Nombre de la remesa"
                        />
                      </div>
                    </div>
                    <hr className="my-5 " />

                    {/* Date of Charge */}
                    <div className="flex flex-col lg:flex-row gap-4">
                      <div className=" w-full lg:w-1/3">
                        <label className="text-sm font-medium">
                          Fecha del cargo
                        </label>
                        <p className="text-sm text-gray-500">
                          ¿Cuándo quieres que se efectue el cobro?
                        </p>
                      </div>
                      <div className="flex-1">
                        <FormikDatePicker
                          name="dateOfCharge"
                          label="Select Date"
                        />
                      </div>
                    </div>
                    <hr className="my-5 " />

                    {/* Concept of the position */}
                    <div className="flex flex-col lg:flex-row gap-4">
                      <div className=" w-full lg:w-1/3">
                        <label className="text-sm font-medium">
                          Concepto del cargo
                        </label>
                        <p className="text-sm text-gray-500">
                          Concepto en el extracto bancario
                        </p>
                      </div>
                      <div className="flex-1">
                        <FormikInput
                          name="position"
                          placeholder="Concepto del cargo"
                        />
                      </div>
                    </div>
                    <hr className="my-5 " />
                    {/* Wrapper of fixed fields */}
                    <div className=" relative">
                      <div className="w-full h-full z-50 absolute bg-opacity-20"></div>

                      {/* Business Name */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className=" w-full lg:w-1/3">
                          <label className="text-sm font-medium">
                            Razon Social
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikInput
                            name="businessName"
                            placeholder="Razon Social"
                            Icon={BsLayers}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* C.I.F. */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className=" w-full lg:w-1/3">
                          <label className="text-sm font-medium">C.I.F.</label>
                        </div>
                        <div className="flex-1">
                          <FormikInput
                            name="cif"
                            placeholder="C.I.F."
                            Icon={BsLayers}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Direction */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className=" w-full lg:w-1/3">
                          <label className="text-sm font-medium">
                            Dirección
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikInput
                            name="direction"
                            placeholder="Dirección"
                            Icon={GrLocation}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Email */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className=" w-full lg:w-1/3">
                          <label className="text-sm font-medium">Email</label>
                        </div>
                        <div className="flex-1">
                          <FormikInput
                            name="email"
                            placeholder="Email"
                            Icon={AiOutlineMail}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* PhoneNo */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className=" w-full lg:w-1/3">
                          <label className="text-sm font-medium">
                            Teléfono
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikInput
                            name="phoneNo"
                            placeholder="Teléfono"
                            Icon={BsTelephone}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Account No */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className=" w-full lg:w-1/3">
                          <label className="text-sm font-medium">
                            Número de cuenta
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikInput
                            name="accountNo"
                            placeholder="Número de cuenta"
                            Icon={FiCreditCard}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* BIC */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className=" w-full lg:w-1/3">
                          <label className="text-sm font-medium">
                            Número de cuenta
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikInput
                            name="bic"
                            placeholder="BIC"
                            Icon={FiCreditCard}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />
                    </div>

                    {/* Buttons */}
                    <div className="flex justify-end flex-col lg:flex-row gap-4">
                      <div className="flex gap-6">
                        <AppButton
                          title="Cancelar"
                          color="black"
                          bgColor="white"
                        />
                        <AppButton
                          title="Guardar y continuar"
                          type="submit"
                          disabled={!formik.isValid}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </TabPanel>

          {/* Second Step */}
          <TabPanel value="2">
            {/* Heading */}
            <div className="flex flex-col-reverse lg:flex-row gap-4 justify-between">
              <div>
                <h3 className="text-lg font-medium">Listado de recibos</h3>
                <p className="text-sm text-gray-500">
                  Edita el listado de recibos remesados
                </p>
              </div>
              <div className="flex gap-6">
                <AppButton
                  title="Cancelar"
                  color="black"
                  bgColor="white"
                  onClick={() => setValue("1")}
                />
                <AppButton
                  title="Guardar y continuar"
                  onClick={() => {
                    setAlertVisible(true);
                  }}
                />
              </div>
            </div>
            <hr className="my-5 " />

            {/* Table Container */}
            <div className="shadow px-4 mt-4">
              {/* Search Field */}
              <div className="w-2/4">
                <InputField
                  placeholder="Buscar"
                  Icon={FiSearch}
                  onChange={handleSearch}
                />
              </div>

              {/* Table */}
              <div className="mt-2">
                <ReceiptTable data={filteredReceipts} />
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-end my-5 flex-col lg:flex-row gap-4">
              <div className="flex gap-6">
                <AppButton
                  title="Cancelar"
                  color="black"
                  bgColor="white"
                  onClick={() => setValue("1")}
                />
                <AppButton
                  title="Guardar y continuar"
                  onClick={() => {
                    setAlertVisible(true);
                  }}
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </Layout>
  );
};

export default NewConsignment;
