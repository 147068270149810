import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AppButton from "../common/AppButton";
import Icon from "../common/Icon";

export default function FormAlert({
  agree,
  disagree,
  open,
  setOpen,
  message,
  desc,
  acceptTitle,
  rejectTitle,
  IconComponent,
  darkColor = "#D1FADF",
  lightColor = "#ECFDF3",
  iconColor = "green",
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-2">
          <DialogContent>
            <div className="mb-4">
              {IconComponent && (
                <Icon
                  IconComponent={IconComponent}
                  darkColor={darkColor}
                  lightColor={lightColor}
                  iconColor={iconColor}
                />
              )}
            </div>
            <DialogContentText
              id="alert-dialog-description"
              style={{ color: "black" }}
            >
              {message}
            </DialogContentText>
            <p className="w-72 my-2 text-gray-500 text-md">{desc}</p>
          </DialogContent>
          <DialogActions>
            <AppButton
              title={rejectTitle}
              color="black"
              bgColor="white"
              onClick={() => {
                handleClose();
                disagree();
              }}
            />
            <AppButton
              title={acceptTitle}
              onClick={() => {
                handleClose();
                agree();
              }}
            />
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
