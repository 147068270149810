import React, { useEffect, useState } from "react";
import Avatar from "../../components/common/Avatar";
import InputField from "../../components/common/InputField";
import MenuItem from "../../components/menu/MenuItem";
import { IoExitOutline } from "react-icons/io5";
import { FiSettings, FiUsers, FiSearch } from "react-icons/fi";
import { TfiMenuAlt } from "react-icons/tfi";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { HiOutlineSupport } from "react-icons/hi";
import { RiBillLine } from "react-icons/ri";
import { ImStack } from "react-icons/im";
import { Link } from "react-router-dom";
import auth from "../../services/authService";
import { IconButton } from "@mui/material";

const Sidebar = ({ activeItem = 1, isOpen, setIsOpen }) => {
  const [user, setUser] = useState({});

  const ToggleSidebar = () => {
    isOpen === true ? setIsOpen(false) : setIsOpen(true);
  };

  useEffect(() => {
    setUser(auth.getCurrentUser());

    return () => {};
  }, []);
  return (
    <>
      {/* Desktop Sidebar */}
      <div className={`bg-white z-50 fixed lg:w-64 hidden lg:flex`}>
        <div
          className={`h-screen flex flex-col max-h-screen border-gray-300 bg-white  overflow-auto px-2 lg:px-6`}
          style={{ borderRightWidth: 1 }}
        >
          <div className="flex mt-8 mb-5 items-center justify-between">
            <h1 className={`text-3xl hidden lg:inline-flex font-medium`}>
              Cris4life
            </h1>
          </div>
          <div className={`hidden lg:flex`}>
            <InputField placeholder="Search" Icon={FiSearch} />
          </div>
          {/* Top Menu Items */}
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col mt-8">
              <Link to="/" replace>
                <MenuItem title="Home" active={activeItem === 1} />
              </Link>
              <Link to="/rates" replace>
                <MenuItem
                  title="Tarifas"
                  IconComponent={ImStack}
                  active={activeItem === 2}
                />
              </Link>
              <Link to="/users" replace>
                <MenuItem
                  title="Usuarios"
                  IconComponent={FiUsers}
                  active={activeItem === 3}
                />
              </Link>
              {user?.isAdmin && (
                <Link to="/billing" replace>
                  <MenuItem
                    title="Facturación"
                    IconComponent={RiBillLine}
                    active={activeItem === 4}
                  />
                </Link>
              )}
              <Link to="/equipement" replace>
                <MenuItem
                  title="Equipo"
                  IconComponent={AiOutlineUnorderedList}
                  active={activeItem === 7}
                />
              </Link>
            </div>
          </div>
          {/* Bottom Menu Items */}
          <div className="mt-16">
            <Link to="/support" replace>
              <MenuItem
                title="Support"
                IconComponent={HiOutlineSupport}
                active={activeItem === 5}
              />
            </Link>
            <Link to="/settings" replace>
              <MenuItem
                title="Settings"
                IconComponent={FiSettings}
                active={activeItem === 6}
              />
            </Link>
            <hr className="w-full h-1" />
            <div className="my-4 flex">
              <div className={`hidden lg:block`}>
                <Avatar name={user.fullName} />
              </div>
              <div className={`lg:ml-2 flex justify-between flex-1`}>
                <div className={`hidden lg:block`}>
                  <h3 className="text-sm font-bold">{user.fullName}</h3>
                  <p className="text-sm text-gray-500 w-28 truncate">
                    {user.email}
                  </p>
                </div>
                <Link to="/logout">
                  <IconButton>
                    <IoExitOutline className="text-xl text-gray-500 cursor-pointer" />
                  </IconButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Sidebar */}
      <div className="lg:hidden ">
        <div className={`sidebar p-6 ${isOpen == true ? "active" : ""}`}>
          <div className="flex mt-8 mb-5 items-center justify-between">
            <h1 className={`text-3xl  lg:inline-flex font-medium`}>
              Cris4life
            </h1>
          </div>
          <div className={` lg:flex`}>
            <InputField placeholder="Search" Icon={FiSearch} />
          </div>
          {/* Top Menu Items */}
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col mt-8">
              <Link to="/" replace>
                <MenuItem title="Home" active={activeItem === 1} />
              </Link>
              <Link to="/rates" replace>
                <MenuItem
                  title="Tarifas"
                  IconComponent={ImStack}
                  active={activeItem === 2}
                />
              </Link>
              <Link to="/users" replace>
                <MenuItem
                  title="Usuarios"
                  IconComponent={FiUsers}
                  active={activeItem === 3}
                />
              </Link>
              <Link to="/billing" replace>
                <MenuItem
                  title="Facturación"
                  IconComponent={RiBillLine}
                  active={activeItem === 4}
                />
              </Link>
            </div>
          </div>
          {/* Bottom Menu Items */}
          <div className="mt-16">
            <Link to="/support" replace>
              <MenuItem
                title="Support"
                IconComponent={HiOutlineSupport}
                active={activeItem === 5}
              />
            </Link>
            <Link to="/settings" replace>
              <MenuItem
                title="Settings"
                IconComponent={FiSettings}
                active={activeItem === 6}
              />
            </Link>
            <hr className="w-full h-1" />
            <div className="my-4 flex">
              <div className={`mr-2 `}>
                <Avatar name={user.fullName} />
              </div>
              <div className={`lg:ml-2 flex justify-between flex-1`}>
                <div className={` lg:block`}>
                  <h3 className="text-sm font-bold">{user.fullName}</h3>
                  <p className="text-sm text-gray-500 w-28 truncate">
                    {user.email}
                  </p>
                </div>
                <Link to="/logout">
                  <IconButton>
                    <IoExitOutline className="text-xl text-gray-500 cursor-pointer" />
                  </IconButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`sidebar-overlay  ${isOpen == true ? "active" : ""}`}
          onClick={ToggleSidebar}
        ></div>
      </div>
    </>
  );
};

export default Sidebar;
