import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import RoundedIcon from "../../components/form/RoundedIcon";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { TabList } from "@mui/lab";
import * as Yup from "yup";
import FormikInput from "../../components/form/FormikInput";
import FileUploader from "../../components/form/FileUploader";
import AppButton from "../../components/common/AppButton";
import RadioCard from "../../components/form/RadioCard";
import FormAlert from "../../components/form/FormAlert";
import { FiCheckCircle } from "react-icons/fi";
import { Form, Formik } from "formik";
import FormikTextArea from "../../components/form/FormikTextArea";
import FormikDatePicker from "../../components/form/FormikDatePicker";
import { AiOutlineCloseCircle, AiOutlineMail } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { BsLayers, BsTelephone } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { getPackages } from "../../services/packageService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  activateClient,
  activateClientLeave,
  getClient,
  unsubscribeClient,
  updateClient,
} from "../../services/clientService";
import { useSnackbar } from "notistack";
import { CircularProgress, LinearProgress } from "@mui/material";
import { getRemittances } from "../../services/billingService";
import PaymentsTable from "../../components/common/tables/PaymentsTable";
import moment from "moment";
import SimpleCheckbox from "../../components/form/SimpleCheckbox";
import CheckboxCard from "../../components/form/ChechboxCard";
import { IoIosAddCircleOutline } from "react-icons/io";

const EditUser = () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertLeave, setAlertLeave] = useState(false);
  const [alertDischarge, setAlertDischarge] = useState(false);
  const [alertReactivate, setAlertReactivate] = useState(false);
  const [newUser, setNewUser] = useState({});
  const [packages, setPackages] = useState([]);
  const [bills, setBills] = useState([]);
  const [user, setUser] = useState({});
  const [activateData, setActivateData] = useState({});
  const [pdfFile, setPdfFile] = useState();
  const [profileImage, setProfileImage] = useState();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const { step, reactivate } = location.state;
  const [value, setValue] = React.useState(step);
  const [leaveData, setLeaveData] = useState({});
  const [unsubData, setUnsubData] = useState({});
  const [reducedRate, setReducedRate] = useState(null);
  const [secondRateId, setSecondRateId] = useState("");

  const accept = async () => {
    const formData = new FormData();
    if (profileImage) formData.append("profileImage", profileImage);
    if (pdfFile) formData.append("pdfFile", pdfFile);
    formData.append("firstName", newUser.firstName);
    formData.append("lastName", newUser.lastName);
    formData.append("nif", newUser.nif);
    formData.append("email", newUser.email);
    formData.append("phoneNo", newUser.phoneNo);
    formData.append("dateOfBirth", newUser.dateOfBirth);
    formData.append("direction", newUser.direction);
    formData.append("observation", newUser.observation);
    formData.append("enrollmentDate", newUser.enrollmentDate);
    formData.append("packageId", newUser.packageId);
    formData.append("accountNo", newUser.accountNo);
    formData.append("BIC", newUser.BIC);

    if (newUser.firstRateStartDate)
      formData.append("firstRateStartDate", newUser.firstRateStartDate);
    if (newUser.firstRateEndDate)
      formData.append("firstRateEndDate", newUser.firstRateEndDate);

    if (reducedRate) {
      formData.append("secondRateId", secondRateId);
      formData.append("secondRateStartDate", newUser.secondRateStartDate);
      if (newUser.secondRateEndDate)
        formData.append("secondRateEndDate", newUser.secondRateEndDate);
    } else {
      formData.append("secondRateId", "");
    }

    setLoading(true);

    try {
      await updateClient(params.id, formData);
      localStorage.setItem("success", "Tu usuario ha sido actualizado");
      navigate("/users", { replace: true });
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.response.data, { variant: "error" });
    }

    setLoading(false);
  };

  const reject = () => {
    console.log("Reject");
  };

  const acceptReactivate = async () => {
    setLoading(true);

    try {
      await activateClient(params.id, activateData);
      localStorage.setItem("success", "Tu usuario ha sido activado");
      navigate("/users", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.response.data, { variant: "error" });
    }

    setLoading(false);
  };
  const rejectReactivate = () => {};

  const acceptLeave = async () => {
    setLoading(true);

    try {
      await activateClientLeave(params.id, leaveData);
      localStorage.setItem("success", "Excedencia activada");
      navigate("/users", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.response.data, { variant: "error" });
    }

    setLoading(false);
  };
  const rejectLeave = () => {};

  const acceptDischarge = async () => {
    setLoading(true);

    try {
      await unsubscribeClient(params.id, unsubData);
      localStorage.setItem("success", "Tu usuario ha sido dado de baja");
      navigate("/users", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.response.data, { variant: "error" });
    }
  };
  const rejectDischarge = () => {};

  const populateUser = async () => {
    setLoading(true);
    try {
      const { data } = await getClient(params.id);
      // console.log(data);
      setUser(data);
      setReducedRate(data.secondRate);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        navigate("/not-found", { replace: true });
      }
    }
    setLoading(false);
  };

  const getAllBills = async () => {
    setLoading(true);
    try {
      const { data } = await getRemittances();

      console.log(data);
      setBills(
        data.map((bill) => {
          const total = bill.users.map((user) => {
            return user.package.monthlyPrice;
          });
          const sum = total.reduce((a, b) => a + b, 0);

          return {
            id: bill._id,
            nameOfRemittance: bill.nameOfRemittance,
            condition:
              moment(bill.dateOfCharge).diff(moment(new Date()), "days") < 0
                ? "Pagada"
                : "Pendiente",
            paymentDate: moment(bill.dateOfCharge).format("DD/MM/YYYY"),
            accountNo: "..." + bill.accountNo.substr(bill.accountNo.length - 4),
            rate: sum + "€",
            users: bill.users,
          };
        })
      );
      // console.log(users);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    loadPackages();
    getAllBills();
    populateUser();

    return () => {};
  }, []);

  const validateFirst = Yup.object({
    firstName: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    nif: Yup.string().required("N.I.F is required"),
    email: Yup.string().email().required("Email is required"),
    phoneNo: Yup.string()
      .min(9, "Must be 9 digits or more")
      .required("Telephone Number is required"),
    dateOfBirth: Yup.date().required("Date is required").nullable(),
    direction: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Direction is required"),
    profileImage: Yup.string().nullable(),
    observation: Yup.string(),
  });

  const validateSecond = Yup.object({
    enrollmentDate: Yup.date().required("Date is required").nullable(),
    firstRateStartDate: Yup.date()
      .required("Rate Start Date is required")
      .nullable(),
    secondRateStartDate: reducedRate
      ? Yup.date().required("Start Date is required").nullable()
      : Yup.date().nullable(),
    secondRateEndDate: Yup.date().nullable(),
    packageId: Yup.string().required("Please select rate."),
  });

  const validateThird = Yup.object({
    accountNo: Yup.string().required("Account Number Required"),
    BIC: Yup.string().required("Swift Code Required"),
  });

  const validateFourth = Yup.object({
    dateOfLeaveStart: Yup.date().required("Start Date is required").nullable(),
    dateOfLeaveEnd: Yup.date().required("End Date is required").nullable(),
    reasonForLeave: Yup.string().required("Reason is required"),
  });

  const validateFifth = Yup.object({
    dateOfDischarge: Yup.date().required("Date is required").nullable(),
    reasonForDischarge: Yup.string().required("Reason is required"),
  });

  const validateReactivate = Yup.object({
    newRegistrationDate: Yup.date().required("Date is required").nullable(),
  });

  const loadPackages = async () => {
    try {
      const { data } = await getPackages();
      setPackages(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // if (value === "4") setValue(newValue);
  };

  // if (true) {
  //   return (
  //     <div className="w-full h-screen flex items-center justify-center">
  //       <CircularProgress color="secondary" />
  //     </div>
  //   );
  // }

  return (
    <Layout activeItem={3}>
      {loading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          {alertLeave && (
            <FormAlert
              agree={acceptLeave}
              disagree={rejectLeave}
              open={alertLeave}
              setOpen={setAlertLeave}
              message="¿Estas seguro de activar excedencia?"
              desc="Este usuario dejará de estar activo en ese rango de fechas"
              acceptTitle="Activar excedencia"
              rejectTitle="Cancelar"
              IconComponent={BiErrorCircle}
              iconColor="#D92D20"
              darkColor="#FEE4E2"
              lightColor="#FEF3F2"
            />
          )}
          {alertReactivate && (
            <FormAlert
              agree={acceptReactivate}
              disagree={rejectReactivate}
              open={alertReactivate}
              setOpen={setAlertReactivate}
              message="¿Quieres activar a este usuario?"
              acceptTitle="Activar"
              rejectTitle="Cancelar"
              IconComponent={FiCheckCircle}
            />
          )}
          {alertDischarge && (
            <FormAlert
              agree={acceptDischarge}
              disagree={rejectDischarge}
              open={alertDischarge}
              setOpen={setAlertDischarge}
              message="¿Estas seguro de dar de baja?"
              desc="Vas a dar de baja a un usuario para la siguente facturación"
              acceptTitle="Dar de baja"
              rejectTitle="Cancelar"
              IconComponent={BiErrorCircle}
              iconColor="#D92D20"
              darkColor="#FEE4E2"
              lightColor="#FEF3F2"
            />
          )}
          {alertVisible && (
            <FormAlert
              agree={accept}
              disagree={reject}
              open={alertVisible}
              setOpen={setAlertVisible}
              message="¿Quieres actualizar este usuario?"
              acceptTitle="Actualizar"
              rejectTitle="Cancelar"
              IconComponent={FiCheckCircle}
            />
          )}

          {loading && <LinearProgress color="secondary" />}
          {/* Title Container */}
          <div className="flex items-center">
            <RoundedIcon text={`${user?.firstName[0]}${user?.lastName[0]}`} />
            <div className="flex flex-col  ml-2">
              <h1 className="text-3xl font-medium">
                {user?.firstName} {user?.lastName}
              </h1>
              <p className="text-base text-gray-500">ID: {user?._id}</p>
            </div>
          </div>

          {/* Tabs Container */}
          <div>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  className="text-purple-500 border-bottom-purple-500"
                  textColor="inherit"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#A855F7",
                    },
                  }}
                >
                  <Tab label="Información" value="1" />
                  <Tab label="Tarifa" value="2" />
                  <Tab label="Facturación" value="3" />
                  <Tab label="Bajas y escedencia" value="4" />
                </TabList>
              </Box>
              {/* First Step */}
              <TabPanel value="1">
                {reactivate && (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      newRegistrationDate: user.newRegDate || "",
                    }}
                    validationSchema={validateReactivate}
                    onSubmit={(values) => {
                      // console.log(values);
                      setActivateData(values);
                      setAlertReactivate(true);
                    }}
                  >
                    {(formik) => (
                      <Form>
                        {/* Reactivate Heading */}
                        <div>
                          <h3 className="text-lg font-medium">
                            Activar usuario
                          </h3>
                          <p className="text-sm text-gray-500">
                            Este usuario volverá a inscribirse
                          </p>
                        </div>

                        <hr className="my-5 " />
                        {/* New Registration Date */}
                        <div className="flex flex-col lg:flex-row gap-4">
                          <label className="text-sm w-full lg:w-1/4 font-medium">
                            Nueva fecha de inscripción
                          </label>
                          <div className="flex-1">
                            <FormikDatePicker
                              formik={formik}
                              label="Select Date"
                              name="newRegistrationDate"
                            />
                          </div>
                        </div>
                        <hr className="my-10 " />
                        {/* Reactivate Buttons */}
                        <div className="flex gap-6 justify-end">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                            onClick={() => {
                              navigate("/users", { replace: true });
                            }}
                          />
                          <AppButton
                            title="Activar usuario"
                            type="submit"
                            bgColor="#039855"
                            disabled={!formik.isValid}
                          />
                        </div>
                        <hr className="my-5 " />
                      </Form>
                    )}
                  </Formik>
                )}
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    firstName: user.firstName || "",
                    lastName: user.lastName || "",
                    nif: user.nif || "",
                    email: user.email || "",
                    phoneNo: user.phoneNo || "",
                    dateOfBirth: user.dateOfBirth || "",
                    direction: user.direction || "",
                    profileImage: user.profileImageUrl || null,
                    observation: user.observation || "",
                  }}
                  validationSchema={validateFirst}
                  onSubmit={(values) => {
                    setNewUser(values);
                    setValue("2");
                    // console.log(values);
                    // handleSubmit(values);
                  }}
                >
                  {(formik) => (
                    <Form
                      className={reactivate && "relative overflow-hidden z-20"}
                    >
                      {reactivate && (
                        <div className="w-full h-full mx-auto bg-black bg-opacity-30 absolute z-20"></div>
                      )}
                      {/* Heading */}
                      <div className="flex flex-col-reverse lg:flex-row gap-4 justify-between">
                        <div>
                          <h3 className="text-lg font-medium">
                            Datos personales
                          </h3>
                          <p className="text-sm text-gray-500">
                            Añade todos los datos personales del nuevo usuario
                          </p>
                        </div>
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                          />
                          <AppButton
                            title="Guardar"
                            type="submit"
                            disabled={!formik.isValid}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* First Name and Last Name */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Nombre y apellido
                        </label>
                        <div className="flex flex-1 gap-8 flex-col lg:flex-row">
                          <FormikInput
                            name="firstName"
                            placeholder="First Name"
                          />
                          <FormikInput
                            name="lastName"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* N.I.F */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          N.I.F.
                        </label>
                        <div className="flex-1">
                          <FormikInput
                            name="nif"
                            placeholder="N.I.F"
                            Icon={BsLayers}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Email */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Email
                        </label>
                        <div className="flex-1">
                          <FormikInput
                            Icon={AiOutlineMail}
                            name="email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Phone Number */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Teléfono
                        </label>
                        <div className="flex-1">
                          <FormikInput
                            Icon={BsTelephone}
                            name="phoneNo"
                            placeholder="Telephone No..."
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Date of Birth */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Fecha de nacimiento
                        </label>
                        <div className="flex-1">
                          <FormikDatePicker
                            formik={formik}
                            label="Select Date"
                            name="dateOfBirth"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Direction */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Dirección
                        </label>
                        <div className="flex-1">
                          <FormikInput
                            name="direction"
                            placeholder="Dirección"
                            Icon={GrLocation}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Profile Picture */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Foto de perfil
                        </label>
                        <div className="flex-1 relative">
                          <FileUploader
                            formik={formik}
                            fieldName="profileImage"
                            // onChange={(e) => console.log("Image Changed.")}
                            setFile={setProfileImage}
                          />
                          <p className="absolute text-xs text-red-500 -mt-4">
                            {formik.errors.profileImage}
                          </p>
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Observation */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className="w-full lg:w-1/4 flex flex-col">
                          <label className="text-sm font-medium">
                            Observaciones
                          </label>
                          <label className="text-sm text-gray-500">
                            Necesidades especiales
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikTextArea
                            name="observation"
                            placeholder="Observation..."
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Buttons */}
                      <div className="flex justify-end">
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                          />
                          <AppButton
                            title="Guardar"
                            type="submit"
                            disabled={!formik.isValid}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </TabPanel>

              {/* Second Step */}
              <TabPanel value="2">
                <Formik
                  initialValues={{
                    enrollmentDate: user.enrollmentDate || "",
                    secondRateStartDate: user.secondRateStartDate || null,
                    secondRateEndDate: user.secondRateEndDate || null,
                    firstRateStartDate: user.firstRateStartDate || null,
                    firstRateEndDate: user.firstRateEndDate || null,
                    packageId: user?.package?._id || "",
                  }}
                  validationSchema={validateSecond}
                  onSubmit={(values) => {
                    setNewUser({ ...newUser, ...values });
                    setValue("3");
                    // console.log(values);

                    // console.log(newUser);
                    // handleSubmit(values);
                  }}
                >
                  {(formik) => (
                    <Form>
                      {/* Heading */}
                      <div className="flex justify-between flex-col-reverse lg:flex-row gap-4">
                        <div>
                          <h3 className="text-lg font-medium">Tarifa</h3>
                          <p className="text-sm text-gray-500">
                            Selecciona la tarifa del usuario
                          </p>
                        </div>
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                            onClick={() => setValue("1")}
                          />
                          <AppButton title="Guardar" type="submit" />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Enrollment Date */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Fecha de inscripción
                        </label>
                        <div className="flex-1">
                          <FormikDatePicker
                            formik={formik}
                            label="Select Date"
                            name="enrollmentDate"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Reduced Rate Fee */}
                      {reducedRate && (
                        <div>
                          {/* Rate Date */}
                          <div className="flex flex-col lg:flex-row gap-4 mb-10">
                            <label className="text-sm w-full lg:w-1/4 font-medium">
                              Fecha de la tarifa
                            </label>
                            <div className="flex-1">
                              <FormikDatePicker
                                formik={formik}
                                label="Start Date"
                                name="secondRateStartDate"
                              />
                            </div>
                            <div className="flex-1">
                              <FormikDatePicker
                                formik={formik}
                                label="End Date"
                                name="secondRateEndDate"
                              />
                            </div>
                          </div>

                          <div className="flex flex-col lg:flex-row gap-4">
                            <div className="w-full lg:w-1/4 flex flex-col">
                              <label className="text-sm font-medium ">
                                Seleccion de tarifa
                              </label>
                              <label className="text-sm text-gray-500">
                                Eliga la tarifa
                              </label>
                            </div>
                            <div className="flex-1" onChange={() => {}}>
                              <CheckboxCard
                                pack={reducedRate}
                                checked={true}
                                readOnly
                              />

                              {/* Remove Rate */}
                              <div className="flex justify-end p-5">
                                <div
                                  className="flex gap-2 cursor-pointer items-center"
                                  onClick={() => {
                                    setReducedRate(null);
                                    setSecondRateId("");
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    size={20}
                                    color="#ff0000"
                                  />
                                  <p className="text-md text-red-500">
                                    Eliminar tarifa
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {reducedRate && <hr className="my-5 " />}

                      {/* Rate Date */}
                      <div className="flex flex-col lg:flex-row gap-4 mb-10">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Fecha de la tarifa
                        </label>
                        <div className="flex-1">
                          <FormikDatePicker
                            formik={formik}
                            label={"Start Date"}
                            name="firstRateStartDate"
                          />
                        </div>

                        <div className="flex-1">
                          <FormikDatePicker
                            formik={formik}
                            label="End Date"
                            name="firstRateEndDate"
                          />
                        </div>
                      </div>

                      {/* Packages */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className="w-full lg:w-1/4 flex flex-col">
                          <label className="text-sm  font-medium">
                            Seleccion de tarifa
                          </label>
                          <label className="text-sm text-gray-500">
                            Eliga la tarifa
                          </label>
                        </div>
                        <div
                          className="flex-1"
                          onChange={(e) =>
                            formik.setFieldValue("packageId", e.target.value)
                          }
                          defaultValue={formik.values.packageId}
                        >
                          {packages.map((pack) => {
                            return (
                              <RadioCard
                                key={pack._id}
                                pack={pack}
                                readOnly={true}
                                checked={formik.values.packageId === pack._id}
                              />
                            );
                          })}

                          <div className="text-sm text-red-500">
                            {formik.errors.packageId}
                          </div>
                        </div>
                      </div>

                      {/* Add Rate */}
                      {reducedRate === null && (
                        <div className="flex justify-end p-5">
                          <div
                            className="flex gap-2 cursor-pointer items-center"
                            onClick={() => {
                              let p = packages.filter(
                                (pack) => pack._id === formik.values.packageId
                              );
                              if (formik.values.packageId) {
                                setSecondRateId(p[0]._id);
                                setReducedRate({ ...p[0], _id: "123456" });
                                formik.setFieldValue(
                                  "secondRateStartDate",
                                  formik.values.firstRateStartDate
                                );
                                formik.setFieldValue(
                                  "secondRateEndDate",
                                  formik.values.firstRateEndDate
                                );
                                formik.setFieldValue("packageId", "");
                                // formik.setFieldValue("firstRateDateStart", null);
                                // formik.setFieldValue("firstRateDateEnd", null);
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            <IoIosAddCircleOutline size={20} color="#9a1af0" />
                            <p className="text-md text-purple-500">
                              Añadir otra tarifa
                            </p>
                          </div>
                        </div>
                      )}

                      <hr className="my-5 " />

                      {/* Buttons */}
                      <div className="flex justify-end">
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                            onClick={() => setValue("1")}
                          />
                          <AppButton title="Guardar" type="submit" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </TabPanel>

              {/* Third Step */}
              <TabPanel value="3">
                <Formik
                  initialValues={{
                    accountNo: user.accountNo,
                    BIC: user.BIC || "",
                  }}
                  validationSchema={validateThird}
                  onSubmit={(values) => {
                    setNewUser({ ...newUser, ...values });
                    // console.log(newUser);
                    setAlertVisible(true);
                    // handleSubmit(values);
                  }}
                >
                  {(formik) => (
                    <Form>
                      {/* Heading */}
                      <div className="flex justify-between flex-col-reverse lg:flex-row gap-4 ">
                        <div>
                          <h3 className="text-lg font-medium">
                            Información de facturación
                          </h3>
                          <p className="text-sm text-gray-500">
                            Añade el numero de cuenta del usuario
                          </p>
                        </div>
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                            onClick={() => setValue("2")}
                          />
                          <AppButton title="Guardar" type="submit" />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Account No */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Número de cuenta{" "}
                        </label>
                        <div className="flex-1">
                          <FormikInput
                            name="accountNo"
                            placeholder="Número de cuenta"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Swift Code / BIC */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Código SWIFT/BIC
                        </label>
                        <div className="flex-1">
                          <FormikInput
                            name="BIC"
                            placeholder="Código SWIFT/BIC"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Terms and Conditions */}

                      <div className="flex flex-col lg:flex-row gap-4 mt-10">
                        <div className="w-full lg:w-1/4 flex flex-col">
                          <label className="text-sm font-medium ">
                            Terminos y condiciones
                          </label>
                          <label className="text-sm text-gray-500">
                            Revisa y acepta los términos
                          </label>
                        </div>
                        <div className="flex-1">
                          <SimpleCheckbox
                            disabled
                            defaultChecked
                            name="terms"
                            value="1"
                            title="Pagos"
                            desc="Acepto el cobro de los recibos según mi tarifa mediante domiciliación bancaria."
                          />
                          <SimpleCheckbox
                            disabled
                            defaultChecked
                            name="terms"
                            value="2"
                            title="Normas"
                            desc="He leido y acepto las normas del centro. Con la norma principal: Cambios de tarifa y solicitud de baja antes del día 20 de cada mes"
                          />
                          <SimpleCheckbox
                            disabled
                            defaultChecked
                            name="terms"
                            value="3"
                            title="Derechos de imagen"
                            desc="He leido y acepto la cesión de imágenes para redes sociales."
                          />
                        </div>
                      </div>

                      <hr className="my-5 " />
                      <div className="shadow p-4 mt-4">
                        <h3>Pagos</h3>
                        <div className="mt-4">
                          <PaymentsTable data={bills} user={user} />
                        </div>
                      </div>

                      {/* Buttons */}
                      <div className="flex justify-end  flex-col lg:flex-row gap-4">
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                            onClick={() => setValue("2")}
                          />
                          <AppButton title="Guardar" type="submit" />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </TabPanel>

              {/* Step Four */}
              <TabPanel value="4">
                <Formik
                  initialValues={{
                    dateOfLeaveStart: user.leaveDate?.startDate || null,
                    dateOfLeaveEnd: user.leaveDate?.endDate || null,
                    reasonForLeave: user?.reasonOfLeave || "",
                  }}
                  validationSchema={validateFourth}
                  onSubmit={(values) => {
                    // console.log(values);
                    setLeaveData({
                      leaveDateStart: values.dateOfLeaveStart,
                      leaveDateEnd: values.dateOfLeaveEnd,
                      reasonOfLeave: values.reasonForLeave,
                      status: "Excedencia",
                    });
                    setAlertLeave(true);
                    // handleSubmit(values);
                  }}
                >
                  {(formik) => (
                    <Form>
                      {/* Heading 1 */}
                      <div className="flex justify-between">
                        <div>
                          <h3 className="text-lg font-medium">
                            Activar excedencia
                          </h3>
                          <p className="text-sm text-gray-500">
                            Este usuario dejará de estar activo en ese rango de
                            fechas
                          </p>
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Date of Leave */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Fecha de la excedencia
                        </label>
                        <div className="flex-1 mb-4">
                          <FormikDatePicker
                            formik={formik}
                            label="Start Date"
                            name="dateOfLeaveStart"
                          />
                        </div>
                        <div className="flex-1 mb-4">
                          <FormikDatePicker
                            formik={formik}
                            label="End Date"
                            name="dateOfLeaveEnd"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Reason For Leave */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className="w-full lg:w-1/4 flex flex-col">
                          <label className="text-sm font-medium">
                            Motivo de la excedencia
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikTextArea
                            name="reasonForLeave"
                            placeholder="Motivo de la excedencia..."
                          />
                          <p className="text-red-500 text-sm">
                            {formik.errors.reasonForLeave}
                          </p>
                        </div>
                      </div>

                      {/* Buttons */}
                      <div className="flex justify-end mt-5 flex-col lg:flex-row gap-4">
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                            onClick={() =>
                              navigate("/users", { replace: true })
                            }
                          />
                          <AppButton
                            title="Guardar"
                            type="submit"
                            disabled={!formik.isValid}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />
                    </Form>
                  )}
                </Formik>

                <Formik
                  initialValues={{
                    dateOfDischarge: user.unsubDate || null,
                    reasonForDischarge: user.reasonOfUnsub || "",
                  }}
                  validationSchema={validateFifth}
                  onSubmit={(values) => {
                    // console.log(values);
                    setAlertDischarge(true);
                    setUnsubData({
                      unsubDate: values.dateOfDischarge,
                      reasonOfUnsub: values.reasonForDischarge,
                      status: "Inactivo",
                    });
                    // handleSubmit(values);
                  }}
                >
                  {(formik) => (
                    <Form>
                      {/* Heading 2 */}
                      <div className="flex justify-between">
                        <div>
                          <h3 className="text-lg font-medium">
                            Dar de baja a este usuario
                          </h3>
                          <p className="text-sm text-gray-500">
                            Este usuario dejará de estar activo
                          </p>
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Discharge Date */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Fecha de baja
                        </label>
                        <div className="flex-1 mb-4">
                          <FormikDatePicker
                            formik={formik}
                            label="Select Date"
                            name="dateOfDischarge"
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />

                      {/* Reason For Discharge */}
                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className="w-full lg:w-1/4 flex flex-col">
                          <label className="text-sm font-medium">
                            Motivo de la baja
                          </label>
                        </div>
                        <div className="flex-1">
                          <FormikTextArea
                            name="reasonForDischarge"
                            placeholder="Motivo de la baja..."
                          />
                          <p className="text-red-500 text-sm">
                            {formik.errors.reasonForDischarge}
                          </p>
                        </div>
                      </div>

                      {/* Buttons */}
                      <div className="flex justify-end mt-5 flex-col lg:flex-row gap-4">
                        <div className="flex gap-6">
                          <AppButton
                            title="Cancelar"
                            color="black"
                            bgColor="white"
                            onClick={() =>
                              navigate("/users", { replace: true })
                            }
                          />
                          <AppButton
                            title="Guardar"
                            color="white"
                            bgColor="red"
                            type="submit"
                            disabled={!formik.isValid}
                          />
                        </div>
                      </div>
                      <hr className="my-5 " />
                    </Form>
                  )}
                </Formik>
              </TabPanel>
            </TabContext>
          </div>
        </>
      )}
    </Layout>
  );
};

export default EditUser;
