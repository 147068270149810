import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import { billingColumns } from "../../../assets/data/billingTableSource";
import { Link } from "react-router-dom";
import { FiDownloadCloud, FiEdit2 } from "react-icons/fi";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function BillingTable({
  data,
  selectionModel,
  setSelectionModel,
}) {
  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        return (
          <div className="flex gap-4 items-center">
            <a href={params.row.xmlUrl}>
              <FiDownloadCloud className="text-gray-500 text-lg" />
            </a>
            {params.row.condition === "Pendiente" ? (
              <Link
                to={`/billing/pending-remittance/${params.id}`}
                replace={true}
              >
                <FiEdit2 className="text-gray-500 text-lg" />
              </Link>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <DataGrid
        pagination
        pageSize={10}
        components={{
          Pagination: CustomPagination,
        }}
        sx={{}}
        rows={data}
        columns={billingColumns.concat(actionColumn)}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
    </Box>
  );
}
