import React, { useCallback } from "react";
import cloud from "../../assets/images/cloud.png";
import { useDropzone } from "react-dropzone";

const FileUploader = ({
  formik,
  fieldName,
  setFile,
  acceptTypes = "image/jpeg,image/png,image/svg",
  ...rest
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      console.log(files[0]);
      console.log(formik);
      formik.setFieldValue(fieldName, files[0].path);
      setFile(files[0]);
    },
    accept: acceptTypes,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="mb-4">
      <div
        {...getRootProps({
          className:
            "flex flex-col items-center justify-center w-full h-32 border border-2 border-gray-100 bg-white rounded-md shadow p-4 hover:bg-gray-100",
        })}
      >
        <input {...getInputProps()} {...rest} />
        <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
          <img src={cloud} className="w-6 my-4" />
        </div>
        <div>
          <span className="text-purple-500">Click to upload</span> or drag and
          drop
        </div>
        <p className="text-gray-500">SVG, PNG, JPG or GIF (max. 800x400px)</p>
      </div>
      <aside className="">
        <ul>{formik && formik.values[fieldName]}</ul>
      </aside>
    </section>
  );
};

export default FileUploader;
