import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { FiDownloadCloud } from "react-icons/fi";
import { paymentsColumns } from "../../../assets/data/paymentsTableSource";
import { jsPDF } from "jspdf";
import ReactDOMServer from "react-dom/server";
import { invoiceTemplate } from "../../template/invoiceTemplate";
import logo from "../../../assets/images/crislogo.png";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function PaymentsTable({ data, user }) {
  const [receipts, setReceipts] = useState([]);
  useEffect(() => {
    let filteredData = data?.filter((bill) => {
      const exist = bill.users.some((u) => u._id === user._id);
      return exist;
    });
    filteredData = filteredData.map((bill) => {
      bill.users = bill.users.filter((u) => u._id === user._id);
      const firstAmt = bill.users[0]?.package?.monthlyPrice || 0;
      const secondAmt = bill.users[0]?.secondRate?.monthlyPrice || 0;
      const totalAmt = firstAmt + secondAmt;
      return {
        id: bill.id,
        nameOfRemittance: bill.nameOfRemittance,
        condition: bill.condition,
        paymentDate: bill.paymentDate,
        accountNo: bill.accountNo,
        rate: totalAmt + "€",
        users: bill.users,
      };
    });
    setReceipts(filteredData);
  }, [user]);

  const generatePdf = (id) => {
    // html2canvas(
    //   new DOMParser().parseFromString(invoiceTemplate(), "text/html")
    // ).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   pdf.save("download.pdf");
    // });

    let remittance = data.filter((item) => item.id === id);
    remittance = remittance[0];

    const lEFT_POSITION = 50;
    const RIGHT_POSITION = 700;
    const firstAmt = user?.package?.monthlyPrice || 0;
    const secondAmt = user?.secondRate?.monthlyPrice || 0;
    const totalAmt = firstAmt + secondAmt;
    const amt = totalAmt / 1.21;
    const vat = totalAmt - amt;
    const doc = new jsPDF("p", "mm", [805, 952]);
    doc.setDrawColor(230, 232, 240);
    doc.addImage(logo, "png", lEFT_POSITION, 10, 80, 30);
    doc.setFont("helvetica");
    doc.setFontSize(45);
    doc.text("PAGADO", RIGHT_POSITION - 35, 30);
    doc.setFontSize(24);
    doc.text(`Invoice #${remittance.id}`, RIGHT_POSITION - 110, 40);
    doc.text(
      "Cris4life Woman Center SL Calle Cardiff 1, 28232, Las Rozas",
      lEFT_POSITION,
      70,
      { maxWidth: 130 }
    );
    doc.text("CIF B02963775", 250, 70);
    doc.text(
      "Email: info@cris4life.es\nTel: 628257623",
      RIGHT_POSITION - 60,
      70
    );
    doc.setFontSize(28);
    doc.text("Fecha de factura", lEFT_POSITION, 100);
    doc.setFontSize(24);
    doc.text(`${remittance.paymentDate}`, lEFT_POSITION, 110);
    doc.setFontSize(28);
    doc.text("Facturado a", lEFT_POSITION, 140);
    doc.setFontSize(24);
    doc.text(`${user.firstName} ${user.lastName}`, lEFT_POSITION, 155);
    doc.text(`#${user._id}`, lEFT_POSITION, 165);
    doc.text(`${user.direction}`, lEFT_POSITION, 175);
    doc.setFontSize(28);
    doc.text("Description", lEFT_POSITION, 205);
    doc.text("Precio", RIGHT_POSITION, 205);
    doc.line(lEFT_POSITION, 215, RIGHT_POSITION + 25, 215);
    doc.setFontSize(24);
    doc.text("Mensualidad Cris4life", lEFT_POSITION, 235);
    doc.text("Importe", RIGHT_POSITION - 100, 235);
    doc.text(`${amt.toFixed(2)}€`, RIGHT_POSITION, 235);
    doc.line(lEFT_POSITION, 255, RIGHT_POSITION + 25, 255);
    doc.text("IVA 21%", RIGHT_POSITION - 100, 275);
    doc.text(`${vat.toFixed(2)}€`, RIGHT_POSITION, 275);
    doc.line(lEFT_POSITION, 295, RIGHT_POSITION + 25, 295);
    doc.text("Total", RIGHT_POSITION - 100, 315);
    doc.text(`${totalAmt.toFixed(2)}€`, RIGHT_POSITION, 315);
    doc.line(lEFT_POSITION, 335, RIGHT_POSITION + 25, 335);

    doc.save("invoice.pdf");
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-4 items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                generatePdf(params.row.id);
              }}
            >
              <FiDownloadCloud className="text-gray-500 text-lg" />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <DataGrid
        pagination
        pageSize={7}
        components={{
          Pagination: CustomPagination,
        }}
        sx={{}}
        rows={receipts}
        columns={paymentsColumns.concat(actionColumn)}
      />
    </Box>
  );
}
