import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React from "react";
import { Box } from "@mui/material";

const AppDatePicker = ({ label, value, setValue }) => {
  // const [value, setValue] = React.useState(null);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={value}
          PopperProps={{
            sx: {
              // "& .MuiPickersDay-dayWithMargin:hover": {
              //   backgroundColor: "purple",
              //   color: "white",
              // },
              // "& .MuiPickersDay-dayWithMargin:selected": {
              //   backgroundColor: "purple",
              //   color: "white",
              // },
              // "& .MuiTabs-root": {
              //   backgroundColor: "rgba(120, 120, 120, 0.4)",
              // },
            },
          }}
          inputFormat="dd/MM/yyyy"
          onChange={(newValue) => {
            setValue(newValue);
            // console.log("Date Filter: ", newValue);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #D0D5DD",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              <input
                ref={inputRef}
                {...inputProps}
                className="outline-none bg-transparent flex-1"
                placeholder={label}
              />
              <span>{InputProps?.endAdornment}</span>
            </Box>
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default AppDatePicker;
