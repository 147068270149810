import React from "react";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const SimpleCheckbox = ({ title, desc, ...props }) => {
  return (
    <div className="flex mb-4">
      <Checkbox id={props.value} {...props} color="secondary" />
      <label className="flex flex-col" htmlFor={props.value}>
        <h2 className="text-sm font-medium">{title}</h2>
        <p className="text-gray-500 text-sm">{desc}</p>
      </label>
    </div>
  );
};

export default SimpleCheckbox;
