import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + "/history";

export function getClients() {
  return http.get(apiEndpoint);
}

export function getHistoryGraphData() {
  return http.get(`${apiEndpoint}/graph`);
}

export function getClientRateCount(id) {
  return http.get(`${apiEndpoint}/rate/${id}`);
}
