import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import Chart from "./Chart";
import LineChart from "../charts/LineChart";

const Widget = ({
  info = { id: 1, title: "Title", price: "12", percentage: "2", status: "up" },
}) => {
  return (
    <div className="shadow p-2 rounded-md overflow-hidden">
      {/* Top Container */}
      <div className="flex justify-between">
        <h3 className="text-base font-medium ">{info.title}</h3>
      </div>

      {/* Bottom Container */}
      <div className="flex relative">
        {/* Left */}
        <div className="flex flex-col mt-4 w-3/5">
          <h3 className="text-4xl font-semibold">{info.total}</h3>
          <div className="flex mt-2 items-center">
            {info.status === "up" ? (
              <BiUpArrowAlt style={{ color: "#12B76A" }} />
            ) : (
              <BiDownArrowAlt style={{ color: "#F04438" }} />
            )}
            <p
              className={`ml-1 text-sm font-medium `}
              style={{ color: info.status === "up" ? "#12B76A" : "#B42318" }}
            >
              {info.percentage}%
            </p>
            <p className="ml-2 text-gray-500 text-sm font-medium">
              vs último mes
            </p>
          </div>
        </div>
        {/* Right */}
        <div className="flex-1 h-full w-2/5">
          <LineChart status={info.status} />
        </div>
      </div>
      {/* Bottom Container End */}
    </div>
  );
};

export default Widget;
