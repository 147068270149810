import React from "react";
import { FiKey } from "react-icons/fi";

const Icon = ({
  IconComponent,
  lightColor,
  darkColor,
  iconColor = "black",
}) => {
  return (
    <div
      className={`w-14 h-14 flex justify-center items-center rounded-full`}
      style={{ backgroundColor: lightColor }}
    >
      <div
        className={`w-10 h-10 flex justify-center items-center rounded-full`}
        style={{ backgroundColor: darkColor }}
      >
        {IconComponent ? (
          <IconComponent className="text-2xl" color={iconColor} />
        ) : (
          <FiKey className="text-2xl" color={iconColor} />
        )}
      </div>
    </div>
  );
};

export default Icon;
