import React, { useState } from "react";
import Layout from "../components/common/Layout";
import FormAlert from "../components/form/FormAlert";
import { FiCheckCircle } from "react-icons/fi";
import ReceiptTable from "../components/common/tables/ReceiptTable";
import { LinearProgress } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Settings = () => {
  return (
    <Layout activeItem={6}>
      <h1>Settings</h1>
    </Layout>
  );
};

export default Settings;
