import { ErrorMessage, useField } from "formik";
import React from "react";

const FormikInput = ({ Icon, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col w-full relative h-11">
      <div
        className={`flex items-center bg-white px-4 shadow h-11 border w-full ${
          meta.touched && meta.error && "border-red-600"
        }`}
        style={{
          border: "1px solid #D0D5DD",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          borderRadius: "8px",
          padding: "10px 14px",
        }}
      >
        {Icon && <Icon className="text-xl text-gray-500 mr-2" />}

        <input
          {...props}
          {...field}
          className={`outline-none appearance-none w-full`}
        />
      </div>
      <ErrorMessage
        component="div"
        name={field.name}
        className="text-sm absolute text-red-600 -bottom-5"
      />
    </div>
  );
};

export default FormikInput;
