import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineCloudDownload, AiOutlinePlus } from "react-icons/ai";
import AppButton from "../../components/common/AppButton";
import InputField from "../../components/common/InputField";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import RateTable from "../../components/common/tables/RateTable";
import { getPackages } from "../../services/packageService";
import { CSVLink } from "react-csv";
import AppAlert from "../../components/form/AppAlert";
import RateWidget from "../../components/common/RateWidget";

const MainRates = () => {
  const [widgets, setWidgets] = useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState("todos");
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [finalPackages, setFinalPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvReport, setCsvReport] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [success, setSuccess] = useState("");

  const handleSelectedFilter = (event, newSelectedFilter) => {
    if (newSelectedFilter !== null) {
      setSelectedFilter(newSelectedFilter);
      let p = packages.filter((pack) => {
        let status = pack.status.toLowerCase();
        return status === newSelectedFilter.toLowerCase();
      });
      setFilteredPackages(p);
      setFinalPackages(p);
      if (newSelectedFilter.toLowerCase() === "todos") {
        setFilteredPackages(packages);
        setFinalPackages(packages);
      }
    }
    console.log(newSelectedFilter);
  };

  useEffect(() => {
    getAllPackages();
    getWidgets();
    window.scrollTo(0, 0);
    setSuccess(localStorage.getItem("success"));

    setTimeout(() => {
      setSuccess("");
      localStorage.setItem("success", "");
    }, 3000);

    return () => {};
  }, []);

  useEffect(() => {
    setFilteredPackages(packages);
    setFinalPackages(packages);

    return () => {};
  }, [packages]);

  const generateCsvReport = (rates) => {
    const headers = [
      { label: "Remesa", key: "name" },
      { label: "Estado", key: "status" },
      { label: "Créditos", key: "credit" },
      { label: "Importe", key: "monthlyPrice" },
    ];

    const data = rates.map((rate) => {
      return {
        id: rate._id,
        name: rate.name,
        status: "Activo",
        credit: rate.credit,
        monthlyPrice: rate.monthlyPrice,
      };
    });

    setCsvData(data);

    setCsvReport({
      data: data,
      headers: headers,
      filename: "rates.csv",
    });
  };

  const getAllPackages = async () => {
    setLoading(true);
    try {
      const { data } = await getPackages();

      setWidgets(
        data.map((pack) => {
          return {
            id: pack._id,
            title: pack.name,
            total: pack.monthlyPrice,
            percentage: 12,
            status: "up",
          };
        })
      );

      setPackages(
        data.map((pack) => ({
          id: pack._id,
          name: pack.name,
          status: "Activo",
          credit: pack.credit,
          monthlyPrice: pack.monthlyPrice + ".00€",
        }))
      );

      generateCsvReport(data);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }

    setLoading(false);
  };

  const getWidgets = async () => {};

  const handleSearch = (e) => {
    let p = filteredPackages.filter((pack) => {
      let name = pack.name.toLowerCase();
      // console.log(username);
      return name.includes(e.target.value.toLowerCase());
    });

    setFinalPackages(p);
  };

  // console.log("Render");

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className="">
      {/* Success Alert */}
      {success && (
        <div className="fixed bottom-5 left-3 right-3 z-50">
          <AppAlert type="success" message={success} />
        </div>
      )}

      {/* Title Container */}
      <div className="">
        <h1 className="text-3xl font-medium">Tarifas</h1>
        <p className="text-sm text-gray-500">Control de la facturación</p>
      </div>

      {/* Main Wigets Container */}
      <div className="grid mt-4 gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {widgets.map((rate, index) => (
          <RateWidget key={index} rate={rate} />
        ))}
      </div>

      {/* Table Container */}
      <div className="shadow p-4 mt-4">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center">
          <h3 className="text-lg font-medium">Tarifas</h3>

          {/* Button Container */}
          <div className="flex flex-row">
            <CSVLink
              {...csvReport}
              onClick={() => {
                if (selectionModel.length === 0) {
                  alert("Please select row.");
                  return false;
                } else {
                  const filteredData = csvData.filter((item) => {
                    const included = selectionModel.includes(item.id);
                    return included;
                  });
                  setCsvReport({ ...csvReport, data: filteredData });
                  return true;
                }
              }}
            >
              <AppButton
                title="Exportar"
                Icon={AiOutlineCloudDownload}
                bgColor="white"
                color="black"
              />
            </CSVLink>
            <Link to="/rates/new" replace>
              <AppButton
                title="Nueva tarifa"
                Icon={AiOutlinePlus}
                extraClasses="ml-2"
              />
            </Link>
          </div>
        </div>

        {/* Filters Container */}
        <div className="flex flex-col lg:flex-row lg:items-center justify-between my-4">
          {/* Selection Container */}
          {/* Left */}
          <div className="flex mb-4">
            <ToggleButtonGroup
              value={selectedFilter}
              exclusive
              onChange={handleSelectedFilter}
              aria-label="text selectedTime"
              className="h-8 w-full"
            >
              <ToggleButton value="todos" aria-label="Todos" className="flex-1">
                <span className="capitalize">Todos</span>
              </ToggleButton>
              <ToggleButton
                value="activo"
                aria-label="Activos"
                className="flex-1"
              >
                <span className="capitalize">Activos</span>
              </ToggleButton>
              <ToggleButton
                value="inactivo"
                aria-label="Inactivos"
                className="flex-1"
              >
                <span className="capitalize">Inactivos</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {/* Right */}
          <div className="flex flex-col lg:flex-row lg:items-center gap-2">
            {/* Search Field */}
            <InputField
              placeholder="Buscar"
              Icon={FiSearch}
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* Table */}
        <div className="mt-2">
          <RateTable
            rates={finalPackages}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
        </div>
      </div>
    </div>
  );
};

export default MainRates;
