import React, { useState } from "react";
import Sidebar from "../../container/Dashboard/Sidebar";
import { FcMenu } from "react-icons/fc";
import { ButtonBase } from "@mui/material";

const Layout = ({ activeItem, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsOpen(false) : setIsOpen(true);
  };

  return (
    <div className="w-full">
      <Sidebar activeItem={activeItem} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="sticky lg:hidden bg-white w-screen z-50 shadow-sm px-2 py-2">
        <ButtonBase className=" w-10 h-10 rounded-full" onClick={ToggleSidebar}>
          <FcMenu size={24} />
        </ButtonBase>
      </div>
      <main className={`min-h-screen h-full  bg-mainColor p-4 flex-1 lg:ml-64`}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
