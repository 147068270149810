import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React from "react";
import { ErrorMessage, Field, useField } from "formik";
import moment from "moment";
import { Box } from "@mui/material";

const FormikDatePicker = ({
  label,
  setIsReducedVisible = () => {},
  ...props
}) => {
  //   const [value, setValue] = React.useState(null);
  const [field1, meta] = useField(props);

  return (
    <Field className="w-full relative" name={field1.name}>
      {({ form, field }) => {
        const { setFieldValue } = form;
        const { value } = field;
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id={field1.name}
                {...props}
                {...field1}
                label={label}
                value={value}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFieldValue(field1.name, newValue);
                  setIsReducedVisible(moment(newValue).date() >= 20);
                }}
                renderInput={(params) => (
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        padding: "8px",
                        width: "280px",
                      }}
                    >
                      <input
                        ref={params.inputRef}
                        {...params.inputProps}
                        className="outline-none bg-transparent"
                        placeholder={label}
                      />
                      {params.InputProps?.endAdornment}
                    </Box>
                    <div className="text-sm text-red-600 absolute">
                      {meta.error}
                    </div>
                  </div>
                )}
              />
            </LocalizationProvider>
          </>
        );
      }}
    </Field>
  );
};

export default FormikDatePicker;
