export const ratesColumn = [
  // {
  //   field: "id",
  //   headerName: "ID",
  //   width: 120,
  //   renderCell: (params) => {
  //     return params.row._id;
  //   },
  // },
  {
    field: "name",
    headerName: "Remesa",
    flex: 1,
    minWidth: 270,
    renderCell: (params) => {
      return (
        <div className="flex items-center text-sm font-medium text-black">
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      let color = "text-gray-500";
      let bgColor = "bg-gray-100";
      let indicatorColor = "bg-green-500";
      switch (params.row.status) {
        case "Borrador":
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
          break;
        case "Inactivo":
          color = "text-red-500";
          bgColor = "bg-red-100";
          indicatorColor = "bg-red-500";
          break;
        case "Activo":
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
          break;
        default:
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
      }
      return (
        <div
          className={`${bgColor} ${color} px-2 py-1 text-xs rounded-full flex justify-center items-center`}
        >
          <div
            className={`w-1.5 h-1.5 rounded-full mr-2 ${indicatorColor} `}
          ></div>
          {params.row.status}
        </div>
      );
    },
  },
  {
    field: "credit",
    headerName: "Créditos",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "monthlyPrice",
    headerName: "Importe",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      return <div>{params.row.monthlyPrice}</div>;
    },
  },
];

//temporary data
export const userRows = [
  {
    id: 1,
    name: "Grupos reducidos 8",
    status: "Activo",
    credit: 8,
    monthlyPrice: 59,
  },
];
