import React from "react";
import { BiArrowBack } from "react-icons/bi";

const BackToLogin = () => {
  return (
    <div className="flex items-center cursor-pointer">
      <BiArrowBack className="text-gray-500 text-xl" />
      <p className="ml-2 text-gray-500 text-sm">Volver a inicio de sesión</p>
    </div>
  );
};

export default BackToLogin;
