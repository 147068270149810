import React from "react";
import Layout from "../../components/common/Layout";
import MainBilling from "../../container/Dashboard/MainBilling";

const Billing = () => {
  return (
    <Layout activeItem={4}>
      <MainBilling />
    </Layout>
  );
};
 
export default Billing;
