import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + "/clients";

export function addClient(client) {
  return http.post(apiEndpoint, client);
}

function clientUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getClient(clientId) {
  return http.get(clientUrl(clientId));
}

export function updateClient(clientId, client) {
  return http.put(clientUrl(clientId), client);
}

export function activateClient(clientId, data) {
  return http.put(`${apiEndpoint}/activate/${clientId}`, data);
}

export function changeClientStatus(clientId, data) {
  return http.put(`${apiEndpoint}/changeStatus/${clientId}`, data);
}

export function activateClientLeave(clientId, data) {
  return http.put(`${apiEndpoint}/leave/${clientId}`, data);
}

export function unsubscribeClient(clientId, data) {
  return http.put(`${apiEndpoint}/inactive/${clientId}`, data);
}

export function getClients() {
  return http.get(apiEndpoint + "?sort=desc");
}

export function getClientsWidgets() {
  return http.get(`${apiEndpoint}/stats`);
}

export function getClientsGraphData() {
  return http.get(`${apiEndpoint}/stats/graph`);
}

export function toggleClient(id) {
  return http.get(`${apiEndpoint}/toggle/${id}`);
}
