import React from "react";
import AppButton from "../../components/common/AppButton";
import Icon from "../../components/common/Icon";
import BackToLogin from "../../components/form/BackToLogin";
import { Link } from "react-router-dom";

import { MdOutlineEmail } from "react-icons/md";

const EmailSent = () => {
  return (
    <div className="flex flex-col h-full w-full items-center mt-24">
      <div className="flex flex-col w-4/5 items-center mx-4 md:w-96">
        <Icon
          IconComponent={MdOutlineEmail}
          darkColor="#FEF0C7"
          lightColor="#FFFAEB"
        />
        <h1 className="text-3xl mt-8 text-center">Revisa tu email</h1>
        <p className="text-gray-400 mt-3 text-center max-w-xs">
          Hemos enviado un link para resetear la contraseña a
          ejemplo@ejemplo.com
        </p>
        <form action="" className="form-vertical form w-full">
          <AppButton title="Abrir mi email" extraClasses="mt-6" />
        </form>
        <div className="text-gray-500 mt-8">
          No has recibido el email?{" "}
          <span className="font-bold">
            <Link to="/forget-password">Click para renviar</Link>
          </span>
        </div>
        <Link to="/login" className="mt-8 ">
          <BackToLogin />
        </Link>
      </div>
    </div>
  );
};

export default EmailSent;
