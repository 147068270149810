import React from "react";
import auth from "../../services/authService";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, redirectTo = "/login" }) => {
  if (!auth.getCurrentUser()) return <Navigate to={redirectTo} replace />;

  return children;
};

export default ProtectedRoute;
