import React from "react";

const Avatar = ({ url, name }) => {
  // console.log(name);
  return (
    <div className="block">
      {name && (
        <div className="h-10 w-10 rounded-full bg-gray-500 flex justify-center items-center">
          <h4 className="text-3xl text-gray-100  uppercase font-bold">
            {name && name.charAt(0).toUpperCase()}
          </h4>
        </div>
      )}
      {url && (
        <img
          className="rounded-full h-10 w-10"
          src="http://anara-africa.com/wp-content/uploads/2016/12/placeholder-profile-male.jpg"
          alt=""
        />
      )}
    </div>
  );
};

export default Avatar;
