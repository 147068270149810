import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import { userColumns } from "../../../assets/data/receiptTableSource";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function ReceiptTable({ data }) {
  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <DataGrid
        pagination
        pageSize={10}
        components={{
          Pagination: CustomPagination,
        }}
        sx={{}}
        rows={data}
        columns={userColumns}
      />
    </Box>
  );
}
