import React from "react";
import AppButton from "../../components/common/AppButton";
import Icon from "../../components/common/Icon";
import BackToLogin from "../../components/form/BackToLogin";
import { Link } from "react-router-dom";

import { FiCheckCircle } from "react-icons/fi";

const PasswordChanged = () => {
  return (
    <div className="flex flex-col h-full w-full items-center mt-24">
      <div className="flex flex-col w-4/5 items-center mx-4 md:w-96">
        <Icon
          IconComponent={FiCheckCircle}
          darkColor="#D1FADF"
          lightColor="#ECFDF3"
          iconColor="green"
        />
        <h1 className="text-3xl mt-8 text-center">Contraseña cambiada</h1>
        <p className="text-gray-400 mt-3 text-center max-w-xs">
          Su contraseña ha sido restablecida con éxito. Haga clic a continuación
          para iniciar sesión mágicamente.
        </p>
        <form action="" className="form-vertical form w-full mt-6">
          <Link to="/">
            <AppButton title="Continuar" />
          </Link>
        </form>

        <Link to="/login" className="mt-8 ">
          <BackToLogin />
        </Link>
      </div>
    </div>
  );
};

export default PasswordChanged;
