import { TextareaAutosize } from "@mui/material";
import { useField } from "formik";
import React from "react";

const FormikTextArea = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextareaAutosize
      {...props}
      {...field}
      name={field.name}
      minRows={6}
      style={{ width: "100%" }}
      className="outline-none appearance-none border rounded-md border-gray-300 shadow p-4"
    />
  );
};

export default FormikTextArea;
