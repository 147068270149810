import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineCloudDownload, AiOutlinePlus } from "react-icons/ai";
import AppButton from "../../components/common/AppButton";
import InputField from "../../components/common/InputField";
import { FiSearch } from "react-icons/fi";
import AppDatePicker from "../../components/common/AppDatePicker";
import { Link } from "react-router-dom";
import Widget from "../../components/common/Widget";
import BillingTable from "../../components/common/tables/BillingTable";
import moment from "moment";
import {
  getBillingGraph,
  getBillingWidgets,
  getRemittances,
} from "../../services/billingService";
import CircularProgress from "@mui/material/CircularProgress";
import BillingChart from "../../components/charts/BillingChart";
import { labels } from "../../assets/data/billingGraphData";
import { CSVLink } from "react-csv";
import AppAlert from "../../components/form/AppAlert";

const MainBilling = () => {
  const [selectedTime, setSelectedTime] = React.useState("m");
  const [selectedFilter, setSelectedFilter] = React.useState("todos");
  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [finalBills, setFinalBills] = useState([]);
  const [date, setDate] = useState(null);
  const [allWidgets, setAllWidgets] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvReport, setCsvReport] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [success, setSuccess] = useState("");

  const handleSelectedTime = (event, newSelectedTime) => {
    if (newSelectedTime !== null) {
      setSelectedTime(newSelectedTime);
      // console.log(newSelectedTime);
      if (newSelectedTime === "m") {
        setWidgets(allWidgets[0]);
      } else if (newSelectedTime === "30d") {
        setWidgets(allWidgets[1]);
      } else if (newSelectedTime === "7d") {
        setWidgets(allWidgets[2]);
      } else if (newSelectedTime === "24h") {
        setWidgets(allWidgets[3]);
      }
    }
  };
  const handleSelectedFilter = (event, newSelectedFilter) => {
    if (newSelectedFilter !== null) {
      setSelectedFilter(newSelectedFilter);
      let b = bills.filter((bill) => {
        let condition = bill.condition.toLowerCase();
        // console.log("Remittance condition: ", condition);
        return condition === newSelectedFilter.toLowerCase();
      });

      setFilteredBills(b);
      setFinalBills(b);
      if (newSelectedFilter.toLowerCase() === "todos") {
        setFilteredBills(bills);
        setFinalBills(bills);
      }
    }
  };

  useEffect(() => {
    getGraphData();
    getAllBills();
    getWidgets();

    window.scrollTo(0, 0);
    setSuccess(localStorage.getItem("success"));

    setTimeout(() => {
      setSuccess("");
      localStorage.setItem("success", "");
    }, 3000);

    return () => {};
  }, []);

  const getWidgets = async () => {
    try {
      const { data } = await getBillingWidgets();
      setAllWidgets(data);
      setWidgets(data[0]);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }
    setLoading(false);
  };

  const getGraphData = async () => {
    try {
      const { data } = await getBillingGraph();
      setGraphData(data);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }
  };

  useEffect(() => {
    let d2 = moment(date).format("DD/MM/YYYY");
    let b = filteredBills.filter((bill) => {
      let d1 = moment(bill.paymentDate).format("DD/MM/YYYY");
      return d1 === d2;
    });

    setFinalBills(b);

    return () => {};
  }, [date]);

  useEffect(() => {
    // console.log("Render Users");
    setFilteredBills(bills);
    setFinalBills(bills);

    return () => {};
  }, [bills]);

  const handleSearch = (e) => {
    let b = filteredBills.filter((bill) => {
      let nameOfRemittance = bill.nameOfRemittance.toLowerCase();
      return nameOfRemittance.includes(e.target.value.toLowerCase());
    });

    setFinalBills(b);
  };

  const generateCsvReport = (bills) => {
    const headers = [
      { label: "Remesa", key: "name" },
      { label: "Estado", key: "status" },
      { label: "Fecha de cobro", key: "paymentDate" },
      { label: "Cuenta de destino", key: "accountNo" },
      { label: "Recibos", key: "noOfUsers" },
      { label: "Facturación", key: "billRate" },
    ];

    const data = bills.map((bill) => ({
      id: bill._id,
      name: bill.nameOfRemittance,
      status:
        moment(bill.dateOfCharge).diff(moment(new Date()), "days") < 0
          ? "Pagada"
          : "Pendiente",
      paymentDate: moment(bill.dateOfCharge).format("DD/MM/YYYY"),
      accountNo: bill.accountNo,
      noOfUsers: bill.users.length,
      billRate: bill.totalAmount,
    }));

    setCsvData(data);

    setCsvReport({
      data: data,
      headers: headers,
      filename: "billing.csv",
    });
  };

  const getAllBills = async () => {
    setLoading(true);
    try {
      const { data } = await getRemittances();
      // console.log(data);
      let prev = 0;
      setBills(
        data.map((bill) => {
          const sum = bill.totalAmount;
          let status = sum > prev ? "up" : "down";
          let percentage = ((sum - prev) / prev) * 100 || 0;
          prev = sum;

          // console.log(
          //   "Check: ",
          //   moment(bill.dateOfCharge).diff(moment(new Date()), "days")
          // );

          return {
            id: bill._id,
            nameOfRemittance: bill.nameOfRemittance,
            condition:
              moment(bill.dateOfCharge).diff(moment(new Date()), "days") < 0
                ? "Pagada"
                : "Pendiente",
            paymentDate: moment(bill.dateOfCharge).format("DD/MM/YYYY"),
            accountNo: "..." + bill.accountNo.substr(bill.accountNo.length - 4),
            rate: bill.users.length,
            billing: {
              billRate: bill.totalAmount + "€",
              state: status,
              percentage:
                status === "up"
                  ? percentage.toFixed() === "Infinity"
                    ? "100"
                    : percentage.toFixed() + "%"
                  : Math.abs(percentage.toFixed()) + "%",
            },
            xmlUrl: bill.xmlUrl,
          };
        })
      );

      generateCsvReport(data);
      // console.log(users);
    } catch (ex) {
      console.log(ex);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className="">
      {/* Success Alert */}
      {success && (
        <div className="fixed bottom-5 left-3 right-3 z-50">
          <AppAlert type="success" message={success} />
        </div>
      )}
      {/* Title Container */}
      <div className="">
        <h1 className="text-3xl font-medium">Facturación</h1>
        <p className="text-sm text-gray-500">Control de la facturación</p>
      </div>

      {/* Days Selection and Date picker Container  */}
      <div className="flex mt-4">
        <ToggleButtonGroup
          value={selectedTime}
          exclusive
          onChange={handleSelectedTime}
          aria-label="text selectedTime"
          className="h-8"
        >
          <ToggleButton value="m" aria-label="12 month">
            <span className="lowercase">12m</span>
          </ToggleButton>
          <ToggleButton value="30d" aria-label="30 days">
            <span className="lowercase">30d</span>
          </ToggleButton>
          <ToggleButton value="7d" aria-label="7 days">
            <span className="lowercase">7d</span>
          </ToggleButton>
          <ToggleButton value="24h" aria-label="24 hours">
            <span className="lowercase">24h</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {/* Main Wigets Container */}
      <div className="grid mt-4 gap-4 grid-cols-1 md:grid-cols-2 mb-4">
        {widgets.map((bill) => (
          <Widget key={bill.id} info={bill} />
        ))}
      </div>

      {/* Line Graph */}
      <div>
        <BillingChart data={graphData} labels={labels} />
      </div>

      {/* Table Container */}
      <div className="shadow p-4 mt-4">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center">
          <h3 className="text-lg font-medium">Remesas</h3>

          {/* Button Container */}
          <div className="flex flex-row">
            <CSVLink
              {...csvReport}
              onClick={() => {
                if (selectionModel.length === 0) {
                  alert("Please select row.");
                  return false;
                } else {
                  const filteredData = csvData.filter((item) => {
                    const included = selectionModel.includes(item.id);
                    return included;
                  });
                  setCsvReport({ ...csvReport, data: filteredData });
                  return true;
                }
              }}
            >
              <AppButton
                title="Exportar"
                Icon={AiOutlineCloudDownload}
                bgColor="white"
                color="black"
              />
            </CSVLink>
            <Link to="/new-consignment" replace>
              <AppButton
                title="Nueva remesa"
                Icon={AiOutlinePlus}
                extraClasses="ml-2"
              />
            </Link>
          </div>
        </div>

        {/* Filters Container */}
        <div className="flex flex-col lg:flex-row lg:items-center justify-between my-4">
          {/* Selection Container */}
          {/* Left */}
          <div className="flex mb-4">
            <ToggleButtonGroup
              value={selectedFilter}
              exclusive
              onChange={handleSelectedFilter}
              aria-label="text selectedTime"
              className="h-8 w-full"
            >
              <ToggleButton value="todos" aria-label="Todos" className="flex-1">
                <span className="capitalize">Todos</span>
              </ToggleButton>
              <ToggleButton
                value="pagada"
                aria-label="Pagadas"
                className="flex-1"
              >
                <span className="capitalize">Pagadas</span>
              </ToggleButton>
              <ToggleButton
                value="pendiente"
                aria-label="Pendientes"
                className="flex-1"
              >
                <span className="capitalize">Pendientes</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {/* Right */}
          <div className="flex flex-col lg:flex-row lg:items-center gap-4">
            {/* Search Field */}
            <InputField
              placeholder="Buscar"
              Icon={FiSearch}
              onChange={handleSearch}
            />

            {/* Datepicker */}
            <AppDatePicker
              label="Fecha de cobro"
              value={date}
              setValue={setDate}
            />
          </div>
        </div>

        {/* Table */}
        <div className="mt-2">
          <BillingTable
            data={finalBills}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
        </div>
      </div>
    </div>
  );
};

export default MainBilling;
