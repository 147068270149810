import RoundedIcon from "../../components/form/RoundedIcon";
import { AiOutlineFile } from "react-icons/ai";
import { BiUpArrowAlt, BiDownArrowAlt, BiCheck } from "react-icons/bi";
export const billingColumns = [
  {
    field: "nameOfRemittance",
    headerName: "Remesa",
    flex: 1,
    minWidth: 180,
    renderCell: (params) => {
      return (
        <div className="flex items-center gap-2 py-8">
          <RoundedIcon Icon={AiOutlineFile} size={16} />
          <span className="text-sm font-medium text-black">
            {params.row.nameOfRemittance}
          </span>
        </div>
      );
    },
  },

  { field: "id", headerName: "ID", width: 100 },
  {
    field: "condition",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      let color = "text-gray-500";
      let bgColor = "bg-gray-100";
      let indicatorColor = "bg-green-500";
      switch (params.row.condition) {
        case "Pendiente":
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
          break;

        case "Pagada":
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
          break;
        default:
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
      }
      return (
        <div
          className={`${bgColor} ${color} px-2 py-1 text-xs rounded-full flex justify-center items-center gap-1`}
        >
          {params.row.condition === "Pagada" ? (
            <BiCheck />
          ) : (
            <div
              className={`w-1.5 h-1.5 rounded-full mr-1 ${indicatorColor} `}
            ></div>
          )}
          {params.row.condition}
        </div>
      );
    },
  },
  {
    field: "paymentDate",
    headerName: "Fecha de cobro",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "accountNo",
    headerName: "Cuenta de destino",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      return (
        <div className="flex items-center">
          <img
            className="w-8"
            src={
              "https://images.assetsdelivery.com/compings_v2/oksananahorna/oksananahorna1803/oksananahorna180300197.jpg"
            }
          />
          {params.row.accountNo}
        </div>
      );
    },
  },
  {
    field: "receipt",
    headerName: "Recibos",
    flex: 1,
    minWidth: 80,
    renderCell: (params) => {
      return <div className={`text-gray-500`}>{params.row.rate}</div>;
    },
  },
  {
    field: "billRate",
    headerName: "Facturación",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      let color = "text-gray-500";
      let bgColor = "bg-gray-100";
      let indicatorColor = "bg-green-500";
      switch (params.row.billing.state) {
        case "up":
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
          break;

        case "down":
          color = "text-red-500";
          bgColor = "bg-red-100";
          indicatorColor = "bg-red-500";
          break;
        default:
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
      }
      return (
        <div className={`text-gray-500 flex gap-2 items-center`}>
          {params.row.billing.billRate}
          <div
            className={`${bgColor} ${color} px-2 py-1 text-xs rounded-full flex justify-center items-center`}
          >
            <span>
              {params.row.billing.state === "up" ? (
                <BiUpArrowAlt className="text-green-500" size={16} />
              ) : (
                <BiDownArrowAlt className="text-red-500" size={16} />
              )}
            </span>
            {params.row.billing.percentage}
          </div>
        </div>
      );
    },
  },
];

//temporary data
export const billingRows = [
  {
    id: 1,
    nameOfRemittance: "Diciembre_2021",
    condition: "Pendiente",
    paymentDate: "19/09/2019",
    accountNo: "...7687",
    rate: "98",
    billing: { billRate: "7394€", state: "up", percentage: "15%" },
  },
  {
    id: 2,
    nameOfRemittance: "Diciembre_2022",
    condition: "Pagada",
    paymentDate: "19/09/2019",
    accountNo: "...7687",
    rate: "98",
    billing: { billRate: "7394€", state: "down", percentage: "15%" },
  },
  {
    id: 3,
    nameOfRemittance: "Diciembre_2023",
    condition: "Pendiente",
    paymentDate: "04/05/2022",
    accountNo: "...7687",
    rate: "98",
    billing: { billRate: "7394€", state: "up", percentage: "15%" },
  },
  {
    id: 4,
    nameOfRemittance: "Diciembre_2024",
    condition: "Pendiente",
    paymentDate: "04/02/2022",
    accountNo: "...7687",
    rate: "98",
    billing: { billRate: "7394€", state: "down", percentage: "8%" },
  },
  {
    id: 5,
    nameOfRemittance: "Diciembre_2025",
    condition: "Pagada",
    paymentDate: "04/01/2022",
    accountNo: "...7687",
    rate: "98",
    billing: { billRate: "7394€", state: "up", percentage: "15%" },
  },
  {
    id: 6,
    nameOfRemittance: "Diciembre_2026",
    condition: "Pagada",
    paymentDate: "04/03/2022",
    accountNo: "...7687",
    rate: "98",
    billing: { billRate: "7394€", state: "up", percentage: "15%" },
  },
  {
    id: 7,
    nameOfRemittance: "Diciembre_2027",
    condition: "Pendiente",
    paymentDate: "04/08/2022",
    accountNo: "...7687",
    rate: "98",
    billing: { billRate: "7394€", state: "up", percentage: "15%" },
  },
];
