import { ErrorMessage, useField } from "formik";
import React from "react";

const Input = ({ label, ...rest }) => {
  const [field, meta] = useField(rest);

  return (
    <div className="mt-4">
      <label
        htmlFor={field.name}
        className="block text-sm font-medium mb-1.5 text-gray-700"
      >
        {label}
      </label>
      <input
        {...rest}
        {...field}
        autoComplete="given-name"
        className={`shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-500 ${
          meta.touched && meta.error && "border-red-600 focus:border-red-500"
        }`}
      />
      <ErrorMessage
        component="div"
        name={field.name}
        className="text-sm absolute text-red-600"
      />
    </div>
  );
};

export default Input;
