import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import { userColumns } from "../../../assets/data/equipementTableSource";
import { Link } from "react-router-dom";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function EquipementTable({
  users,
  selectionModel,
  setSelectionModel,
}) {
  // const [data, setData] = useState(users);

  // const handleDelete = (id) => {
  //   setData(data.filter((item) => item.id !== id));
  // };

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="flex gap-3">
              <Link
                replace
                to={`#`}
                style={{ textDecoration: "none" }}
                state={{ step: "1", reactivate: true }}
              >
                <div className="text-xs text-red-500">Delete</div>
              </Link>
              <Link
                replace
                to={`#`}
                style={{ textDecoration: "none" }}
                state={{ step: "1", reactivate: true }}
              >
                <div className="text-xs text-purple-500">Edit</div>
              </Link>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <DataGrid
        pagination
        pageSize={10}
        components={{
          Pagination: CustomPagination,
        }}
        sx={{}}
        rows={users}
        columns={userColumns.concat(actionColumn)}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
    </Box>
  );
}
