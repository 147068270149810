import React from "react";
import { FiHome } from "react-icons/fi";

const MenuItem = ({ IconComponent, title = "Home", active, hide }) => {
  return (
    <div
      className={`rounded-md  border-gray-500 p-2 flex items-center hover:bg-purple-100 cursor-pointer mb-2 transition-all duration-300 ${
        active ? "text-purple-500 bg-purple-100" : "text-gray-700"
      } `}
    >
      {IconComponent ? (
        <IconComponent
          className={`text-xl ${active ? "text-purple-500" : "text-gray-700"}`}
        />
      ) : (
        <FiHome
          className={`text-xl ${active ? "text-purple-500" : "text-gray-700"}`}
        />
      )}
      <span className="ml-2 lg:inline-flex text-base font-medium">{title}</span>
    </div>
  );
};

export default MenuItem;
