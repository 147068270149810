import moment from "moment";

export const getRates = (user) => {
  let rates = [];
  const FORMAT = "YYYY-MM-DD";
  const currentDate = moment(moment(new Date()).format(FORMAT));
  let [
    firstRateStartDate,
    firstRateEndDate,
    secondRateStartDate,
    secondRateEndDate,
  ] = [null, null, null, null];
  if (user.firstRateStartDate)
    firstRateStartDate = moment(moment(user.firstRateStartDate).format(FORMAT));
  if (user.firstRateEndDate)
    firstRateEndDate = moment(moment(user.firstRateEndDate).format(FORMAT));
  if (user.secondRateStartDate)
    secondRateStartDate = moment(
      moment(new Date(user.secondRateStartDate)).format(FORMAT)
    );
  if (user.secondRateEndDate)
    secondRateEndDate = moment(moment(user.secondRateEndDate).format(FORMAT));

  if (firstRateStartDate) {
    if (firstRateEndDate) {
      if (
        firstRateStartDate.isSameOrBefore(currentDate) &&
        currentDate.isSameOrBefore(firstRateEndDate)
      )
        rates.push(user.firstRate.name);
    } else if (firstRateStartDate.isSameOrBefore(currentDate))
      rates.push(user.firstRate.name);
  }

  if (secondRateStartDate) {
    if (secondRateEndDate) {
      if (
        secondRateStartDate.isSameOrBefore(currentDate) &&
        currentDate.isSameOrBefore(secondRateEndDate)
      )
        rates.push(user.secondRate.name);
    } else if (secondRateStartDate.isSameOrBefore(currentDate))
      rates.push(user.secondRate.name);
  }

  return rates;
};

export const getColors = (name) => {
  switch (name.toLowerCase()) {
    case "4 créditos":
      return { bgColor: "bg-blue-100", color: "text-blue-500" };
    case "6 créditos":
      return { bgColor: "bg-violet-100", color: "text-violet-500" };
    case "8 créditos":
      return { bgColor: "bg-rose-100", color: "text-rose-500" };
    case "12 créditos":
      return { bgColor: "bg-orange-100", color: "text-orange-500" };
    case "16 créditos":
      return { bgColor: "bg-purple-100", color: "text-purple-500" };
    case "ilimitado":
      return { bgColor: "bg-gray-100", color: "text-gray-500" };
    case "entreno personal 4":
      return { bgColor: "bg-amber-100", color: "text-amber-500" };
    case "entreno personal 8":
      return { bgColor: "bg-green-100", color: "text-green-500" };
    default:
      return { bgColor: "bg-gray-100", color: "text-gray-500" };
  }
};

export const calculateControlDigits = (entity, office, accountBank) => {
  let first = 0;
  let second = 0;
  let calc = 0;

  const multi = [1, 2, 4, 8, 5, 10, 9, 7, 3, 6];

  entity.forEach((v, i) => {
    calc += parseInt(v) * multi[i + 2];
  });

  office.forEach((v, i) => {
    calc += parseInt(v) * multi[i + 6];
  });

  calc = 11 - (calc % 11);

  switch (calc) {
    case 10:
      first = 1;
      break;
    case 11:
      first = 0;
      break;
    default:
      first = calc;
      break;
  }

  calc = 0;

  accountBank.forEach((v, i) => {
    calc += parseInt(v) * multi[i];
  });

  calc = 11 - (calc % 11);

  switch (calc) {
    case 10:
      second = 1;
      break;
    case 11:
      second = 0;
      break;
    default:
      second = calc;
      break;
  }

  const digitControl = `${first}${second}`;
  return digitControl;
};
