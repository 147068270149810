export const stackData = [
  {
    name: "Acticos",
    data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43],
  },
  {
    name: "Alatas",
    data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27],
  },
  {
    name: "Bajas",
    data: [11, 17, 15, 15, 21, 14, 11, 17, 15, 15, 21, 14],
  },
];

export const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
