import moment from "moment";
import { getColors } from "../../utils/helper";

export const userColumns = [
  {
    field: "firstName",
    headerName: "Nombre",
    flex: 1,
    minWidth: 250,
    renderCell: (params) => {
      return (
        <div className="flex items-center">
          <div className="w-10 mr-2">
            {params.row.img ? (
              <img
                className="w-10 h-10 rounded-full object-cover"
                src={params.row.img}
                alt=""
              />
            ) : (
              <div className="w-10 h-10 rounded-full flex justify-center items-center bg-purple-100 font-medium text-purple-600">
                {params.row.firstName[0] + params.row.lastName[0]}
              </div>
            )}
          </div>
          <div className="flex flex-col flex-auto">
            <span className="text-sm font-medium text-black">
              {params.row.username}
            </span>
            <span className="text-sm font-normal text-gray-500">
              {params.row.email}
            </span>
          </div>
        </div>
      );
    }
  },
  {
    field: "condition",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      let color = "text-gray-500";
      let bgColor = "bg-gray-100";
      let indicatorColor = "bg-green-500";
      switch (params.row.condition) {
        case "Borrador":
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
          break;
        case "Inactivo":
          color = "text-red-500";
          bgColor = "bg-red-100";
          indicatorColor = "bg-red-500";
          break;
        case "Activo":
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
          break;
        case "Excedencia":
          color = "text-orange-500";
          bgColor = "bg-orange-100";
          indicatorColor = "bg-orange-500";
          break;
        default:
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
      }

      return (
        <div
          className={`${bgColor} ${color} px-2 py-1 text-xs rounded-full flex justify-center items-center`}
        >
          <div
            className={`w-1.5 h-1.5 rounded-full mr-2 ${indicatorColor} `}
          ></div>
          {params.row.condition}
        </div>
      );
    }
  },
  {
    field: "enrollmentDate",
    headerName: "Fecha de inscripción",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      return moment(params.row.enrollmentDate).format("DD/MM/YYYY");
    }
  },
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return params.row._id;
    }
  },
  {
    field: "rate",
    headerName: "Tarifa",
    flex: 1,
    minWidth: 280,
    renderCell: (params) => {
      let rates = [];
      const checkRates = () => {
        const FORMAT = "YYYY-MM-DD";
        const currentDate = moment(moment(new Date()).format(FORMAT));
        let [
          firstRateStartDate,
          firstRateEndDate,
          secondRateStartDate,
          secondRateEndDate
        ] = [null, null, null, null];
        if (params.row.firstRateStartDate)
          firstRateStartDate = moment(
            moment(params.row.firstRateStartDate).format(FORMAT)
          );
        if (params.row.firstRateEndDate)
          firstRateEndDate = moment(
            moment(params.row.firstRateEndDate).format(FORMAT)
          );
        if (params.row.secondRateStartDate)
          secondRateStartDate = moment(
            moment(new Date(params.row.secondRateStartDate)).format(FORMAT)
          );
        if (params.row.secondRateEndDate)
          secondRateEndDate = moment(
            moment(params.row.secondRateEndDate).format(FORMAT)
          );

        if (firstRateStartDate) {
          if (firstRateEndDate) {
            if (
              firstRateStartDate.isSameOrBefore(currentDate) &&
              currentDate.isSameOrBefore(firstRateEndDate)
            )
              rates.push(params.row.firstRate.name);
          } else if (firstRateStartDate.isSameOrBefore(currentDate))
            rates.push(params.row.firstRate.name);
        }

        if (secondRateStartDate) {
          if (secondRateEndDate) {
            if (
              secondRateStartDate.isSameOrBefore(currentDate) &&
              currentDate.isSameOrBefore(secondRateEndDate)
            )
              rates.push(params.row.secondRate.name);
          } else if (secondRateStartDate.isSameOrBefore(currentDate))
            rates.push(params.row.secondRate.name);
        }
      };

      checkRates();

      return (
        <div className="flex gap-2">
          {rates.map((rate, index) => {
            const colors = getColors(rate);
            return (
              <div
                key={index}
                className={`px-2 py-1 rounded-full text-xs ${colors.bgColor} ${colors.color}`}
              >
                {rate}
              </div>
            );
          })}
        </div>
      );
    }
  }
];

//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://i.pravatar.cc/150?img=3",
    condition: "Borrador",
    enrollmentDate: "19/09/2019",
    rate: "Grupos 8"
  },
  {
    id: 2,
    username: "Snow",
    img: "https://i.pravatar.cc/150?img=63",
    condition: "Inactivo",
    enrollmentDate: "19/09/2019",
    rate: "Grupos 12"
  }
];
