import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import React from "react";
import { FormControlLabel } from "@mui/material";
import { toggleClient } from "../../../services/clientService";
import { useSnackbar } from "notistack";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "red" : "#A855F7",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };
const AppSwitch = ({ id, included }) => {
  const [checked, setChecked] = React.useState(included);
  const { enqueueSnackbar } = useSnackbar();

  const toggleUser = async () => {
    try {
      await toggleClient(id);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChange = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      const ok = window.confirm(
        "Are you sure that you want to remove the user from remittance?"
      );
      if (ok) {
        toggleUser(id);
        setChecked(event.target.checked);
        enqueueSnackbar("User Removed from Remittance!", {
          variant: "success",
        });
      }
    } else {
      const ok = window.confirm(
        "Are you sure that you want to add the user in remittance?"
      );
      if (ok) {
        toggleUser(id);
        setChecked(event.target.checked);
        enqueueSnackbar("User Added in Remittance!", {
          variant: "success",
        });
      }
    }
  };
  return (
    <FormControlLabel
      control={
        <IOSSwitch
          checked={checked}
          onChange={handleChange}
          sx={{ m: 1 }}
          // defaultChecked={included}
        />
      }
      label=""
    />
  );
};

export default AppSwitch;
