import React from "react";
import auth from "../../services/authService";
import { Navigate } from "react-router-dom";

const AdminProtectedRoute = ({ children, redirectTo = "/login" }) => {
  if (!auth.getCurrentUser()) return <Navigate to={redirectTo} replace />;

  if (!auth.getCurrentUser()?.isAdmin) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};

export default AdminProtectedRoute;
