import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + "/packages";

function packageUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getPackage(id) {
  return http.get(packageUrl(id));
}

export function addPackage(data) {
  return http.post(apiEndpoint, data);
}

export function getPackages() {
  return http.get(apiEndpoint);
}

export function updatePackage(packageId, data) {
  return http.put(packageUrl(packageId), data);
}
