import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import { ratesColumn } from "../../../assets/data/rateTableSource";
import { Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function RateTable({
  rates,
  selectionModel,
  setSelectionModel,
}) {
  // const [data, setData] = useState(users);

  // const handleDelete = (id) => {
  //   setData(data.filter((item) => item.id !== id));
  // };

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              replace
              to={`/rates/${params.id}`}
              style={{ textDecoration: "none" }}
              state={{ step: "1", reactivate: true }}
            >
              <FiEdit2 className="text-gray-500 text-lg" />
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <DataGrid
        pagination
        pageSize={10}
        components={{
          Pagination: CustomPagination,
        }}
        sx={{}}
        rows={rates}
        columns={ratesColumn.concat(actionColumn)}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
    </Box>
  );
}
