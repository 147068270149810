import http from "./httpService";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + "/users";

export function register(user) {
  return http.post(apiEndpoint, {
    email: user.email,
    password: user.password,
    fullName: user.fullName,
  });
}
export function getUsers() {
  return http.get(apiEndpoint);
}

export function forgetPassword(email) {
  return http.post(`${apiEndpoint}/forget-password`, { email });
}

export function verifyToken(token, id) {
  return http.get(`${apiEndpoint}/verify-token?token=${token}&id=${id}`);
}

export function resetPassword(token, id, password) {
  return http.post(`${apiEndpoint}/reset-password?token=${token}&id=${id}`, {
    password,
  });
}
