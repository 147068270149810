import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import StackChart from "../../components/charts/StackChart";
import Widget from "../../components/common/Widget";
import { AiOutlineCloudDownload, AiOutlinePlus } from "react-icons/ai";
import AppButton from "../../components/common/AppButton";
import InputField from "../../components/common/InputField";
import { FiSearch } from "react-icons/fi";
import AppDatePicker from "../../components/common/AppDatePicker";
import AppTable from "../../components/common/tables/AppTable";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getClients, getClientsWidgets } from "../../services/clientService";
import moment from "moment";
import { CSVLink } from "react-csv";
import AppAlert from "../../components/form/AppAlert";
import { getHistoryGraphData } from "../../services/clientHistoryService";

const AllUsers = () => {
  const [selectedTime, setSelectedTime] = React.useState("m");
  const [totalGraphData, setTotalGraphData] = useState([]);
  const [graphDuration, setGraphDuration] = useState(0);
  const [widgets, setWidgets] = useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState("todos");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [finalUsers, setFinalUsers] = useState([]);
  const [allWidgets, setAllWidgets] = useState([]);
  const [graphAllData, setGraphAllData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [graphLabels, setGraphLabels] = useState([]);
  const [csvReport, setCsvReport] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const graphButtons = ["Usuarios activos", "Altas", "Bajas"];

  const handleSelectedTime = (event, newSelectedTime) => {
    if (newSelectedTime !== null) {
      setSelectedTime(newSelectedTime);
      // console.log(newSelectedTime);
      if (newSelectedTime === "m") {
        setWidgets(allWidgets[0]);
      } else if (newSelectedTime === "30d") {
        setWidgets(allWidgets[1]);
      } else if (newSelectedTime === "7d") {
        setWidgets(allWidgets[2]);
      } else if (newSelectedTime === "24h") {
        setWidgets(allWidgets[3]);
      }
    }
  };

  const handleGraphDuration = (event, newSelected) => {
    if (newSelected !== null) {
      setGraphDuration(newSelected);
      // console.log(newSelected);
      if (newSelected === 0) {
        setGraphAllData(totalGraphData[0][0]);
        setGraphData(totalGraphData[0][0][0]);
        setGraphLabels(totalGraphData[0][1]);
      } else if (newSelected === 1) {
        setGraphAllData(totalGraphData[1][0]);
        setGraphData(totalGraphData[1][0][0]);
        setGraphLabels(totalGraphData[1][1]);
      } else if (newSelected === 2) {
        setGraphAllData(totalGraphData[2][0]);
        setGraphData(totalGraphData[2][0][0]);
        setGraphLabels(totalGraphData[2][1]);
      }
    }
  };

  const handleSelectedFilter = (event, newSelectedFilter) => {
    if (newSelectedFilter !== null) {
      setSelectedFilter(newSelectedFilter);
      let u = users.filter((user) => {
        let condition = user.condition.toLowerCase();
        // console.log("Condition: ", condition);
        return condition === newSelectedFilter.toLowerCase();
      });

      setFilteredUsers(u);
      setFinalUsers(u);
      if (newSelectedFilter.toLowerCase() === "todos") {
        setFilteredUsers(users);
        setFinalUsers(users);
      }
    }

    // console.log(newSelectedFilter);
  };

  useEffect(() => {
    getAllUsers();
    getGraphData();
    getWidgets();

    return () => {};
  }, []);

  useEffect(() => {
    // console.log("Render Users");
    setFilteredUsers(users);
    setFinalUsers(users);

    window.scrollTo(0, 0);
    setSuccess(localStorage.getItem("success"));

    setTimeout(() => {
      setSuccess("");
      localStorage.setItem("success", "");
    }, 3000);

    return () => {};
  }, [users]);

  useEffect(() => {
    let d2 = moment(date).format("DD-MM-YYYY");
    let u = filteredUsers.filter((user) => {
      let d1 = moment(user.enrollmentDate).format("DD-MM-YYYY");
      return d1 === d2;
    });

    setFinalUsers(u);

    return () => {};
  }, [date]);

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const { data } = await getClients();
      setUsers(
        data.map((user) => ({
          id: user._id,
          username: user.firstName + " " + user.lastName,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          img: user.profileImageUrl,
          condition: user.status,
          enrollmentDate: user.enrollmentDate,
          rate: user.package.name.replace("reducidos", ""),
          firstRateStartDate: user.firstRateStartDate,
          firstRateEndDate: user.firstRateEndDate,
          secondRateStartDate: user.secondRateStartDate,
          secondRateEndDate: user.secondRateEndDate,
          firstRate: user.package,
          secondRate: user.secondRate
        }))
      );
      generateCsvReport(data);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }
  };

  const getGraphData = async () => {
    try {
      const { data } = await getHistoryGraphData();
      console.log("Graph Data: ", data);
      setTotalGraphData(data);
      setGraphAllData(data[0][0]);
      setGraphData(data[0][0][0]);
      setGraphLabels(data[0][1]);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }
  };

  const generateCsvReport = (users) => {
    const headers = [
      { label: "Nombre", key: "name" },
      { label: "NIF", key: "nif" },
      { label: "Email", key: "email" },
      { label: "Telephone", key: "phoneNo" },
      { label: "Date of Birth", key: "dateOfBirth" },
      { label: "Account No", key: "accountNo" },
      { label: "Swift Code", key: "BIC" }
    ];

    const data = users.map((user) => ({
      id: user._id,
      name: user.firstName + " " + user.lastName,
      nif: user.nif,
      email: user.email,
      phoneNo: user.phoneNo,
      dateOfBirth: moment(user.dateOfBirth).format("DD/MM/YYYY"),
      accountNo: user.accountNo,
      BIC: user.BIC
    }));

    setCsvData(data);

    setCsvReport({
      data: data,
      headers: headers,
      filename: "users.csv"
    });
  };

  const getWidgets = async () => {
    try {
      const { data } = await getClientsWidgets();
      setAllWidgets(data);
      setWidgets(data[0]);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    let u = filteredUsers.filter((user) => {
      let username = user.username.toLowerCase();
      // console.log(username);
      return username.includes(e.target.value.toLowerCase());
    });

    setFinalUsers(u);
  };

  // console.log("Render");

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className="">
      {/* Success Alert */}
      {success && (
        <div className="fixed bottom-5 left-3 right-3 z-50">
          <AppAlert type="success" message={success} />
        </div>
      )}
      {/* Title Container */}
      <div className="">
        <h1 className="text-3xl font-medium">Usuarios</h1>
        <p className="text-sm text-gray-500">
          Controla y gestiona tus usuarios
        </p>
      </div>

      {/* Days Selection and Date picker Container  */}
      {/* <div className="flex mt-4">
        <ToggleButtonGroup
          value={selectedTime}
          exclusive
          onChange={handleSelectedTime}
          aria-label="text selectedTime"
          className="h-8"
        >
          <ToggleButton value="m" aria-label="12 month">
            <span className="lowercase">12m</span>
          </ToggleButton>
          <ToggleButton value="30d" aria-label="30 days">
            <span className="lowercase">30d</span>
          </ToggleButton>
          <ToggleButton value="7d" aria-label="7 days">
            <span className="lowercase">7d</span>
          </ToggleButton>
          <ToggleButton value="24h" aria-label="24 hours">
            <span className="lowercase">24h</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </div> */}

      {/* Main Wigets Container */}
      <div className="grid mt-4 gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {widgets.map((user) => (
          <Widget key={user.id} info={user} />
        ))}
      </div>

      {/* Graph Container */}
      <div className="shadow p-4 mt-4">
        <div className="flex justify-between w-full">
          <h3 className="text-lg font-medium">Volumen de usuarios</h3>

          {/* Graph Options */}
          <div className="flex">
            <ToggleButtonGroup
              value={graphDuration}
              exclusive
              onChange={handleGraphDuration}
              aria-label="text selectedTime"
              className="h-8"
            >
              <ToggleButton value={0} aria-label="Año">
                <span className="capitalize">Año</span>
              </ToggleButton>
              <ToggleButton value={1} aria-label="Mes">
                <span className="capitalize">Mes</span>
              </ToggleButton>
              <ToggleButton value={2} aria-label="Semana">
                <span className="capitalize">Semana</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="flex gap-2 mt-4">
          {graphButtons.map((item, index) => (
            <button
              key={index}
              className="px-3 py-2 font-medium"
              onClick={() => {
                setSelectedIndex(index);
                setGraphData(graphAllData[index]);
              }}
              style={{
                background: selectedIndex === index ? "#F9F5FF" : "transparent",
                color: selectedIndex === index ? "#6941C6" : "#667085",
                borderRadius: "6px"
              }}
            >
              {item}
            </button>
          ))}
        </div>

        {/* Add Month and Weekly duration in Purple Graph */}
        <StackChart data={[graphData]} labels={graphLabels} />
      </div>

      {/* Table Container */}
      <div className="shadow p-4 mt-4">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center">
          <h3 className="text-lg font-medium">Listado de usuarios</h3>

          {/* Button Container */}

          <div className="flex flex-row">
            {csvData !== undefined && csvData?.length > 0 && (
              <CSVLink
                {...csvReport}
                onClick={() => {
                  if (selectionModel.length === 0) {
                    alert("Please select row.");
                    return false;
                  } else {
                    const filteredData = csvData.filter((item) => {
                      const included = selectionModel.includes(item.id);
                      return included;
                    });
                    setCsvReport({ ...csvReport, data: filteredData });
                    return true;
                  }
                }}
              >
                <AppButton
                  title="Exportar"
                  Icon={AiOutlineCloudDownload}
                  bgColor="white"
                  color="black"
                />
              </CSVLink>
            )}
            <Link to="/add-user" replace>
              <AppButton
                title="Nuevo usuario"
                Icon={AiOutlinePlus}
                extraClasses="ml-2"
              />
            </Link>
          </div>
        </div>

        {/* Filters Container */}
        <div className="flex flex-col lg:flex-row lg:items-center justify-between my-4">
          {/* Selection Container */}
          {/* Left */}
          <div className="flex mb-4">
            <ToggleButtonGroup
              value={selectedFilter}
              exclusive
              onChange={handleSelectedFilter}
              aria-label="text selectedTime"
              className="h-8 w-full"
            >
              <ToggleButton value="todos" aria-label="Todos" className="flex-1">
                <span className="capitalize">Todos</span>
              </ToggleButton>
              <ToggleButton
                value="activo"
                aria-label="Activos"
                className="flex-1"
              >
                <span className="capitalize">Activos</span>
              </ToggleButton>
              <ToggleButton
                value="inactivo"
                aria-label="Inactivos"
                className="flex-1"
              >
                <span className="capitalize">Inactivos</span>
              </ToggleButton>
              <ToggleButton
                value="excedencia"
                aria-label="Excedencia"
                className="flex-1"
              >
                <span className="capitalize">excedencia</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {/* Right */}
          <div className="flex flex-col lg:flex-row  justify-center gap-4">
            {/* Search Field */}
            <InputField
              placeholder="Buscar"
              Icon={FiSearch}
              onChange={handleSearch}
            />

            {/* Datepicker */}
            <AppDatePicker
              label="Fecha de inscripción"
              value={date}
              setValue={setDate}
            />
          </div>
        </div>

        {/* Table */}
        <div className="mt-2">
          <AppTable
            users={finalUsers}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
