import React from "react";

const RadioCard = ({ pack, ...props }) => {
  return (
    <label className="" htmlFor={pack._id}>
      <div className="border rounded-md shadow cursor-pointer pb-4 mb-4">
        <div
          className={`flex justify-between p-4 ${
            props.checked && "bg-purple-100"
          }`}
        >
          <h3 className={`font-medium ${props.checked && "text-purple-500"}`}>
            {pack.name}
          </h3>
          <input
            {...props}
            id={pack._id}
            type="radio"
            value={pack._id}
            name="package"
            className="accent-purple-500"
          />
        </div>
        <hr className="mb-2" />
        <div className="p-4 flex items-end">
          <div className="text-3xl font-semibold">{pack.monthlyPrice}€</div>
          <p className="text-sm text-gray-500 ml-1">al mes</p>
        </div>
        <p className="px-4 text-gray-500 text-sm">{pack.desc}</p>
      </div>
    </label>
  );
};

export default RadioCard;
