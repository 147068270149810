import React from "react";

const RoundedIcon = ({ Icon, text, size }) => {
  return (
    <div className="p-2 bg-purple-50 rounded-full">
      {text && <h3 className="text-purple-500 text-2xl">{text}</h3>}
      {Icon && <Icon className="text-2xl text-purple-500" size={size} />}
    </div>
  );
};

export default RoundedIcon;
