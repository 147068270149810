import React from "react";
import Layout from "../../components/common/Layout";
import AllUsers from "../../container/Dashboard/AllUsers";

const Users = () => {
  return (
    <Layout activeItem={3}>
      <AllUsers />
    </Layout>
  );
};

export default Users;
