import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + "/remittances";

export function addRemittance(remittance) {
  return http.post(apiEndpoint, remittance);
}

export function updateRemittance(remittance, id) {
  return http.put(remittanceUrl(id), remittance);
}

function remittanceUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getRemittances() {
  return http.get(apiEndpoint);
}

export function getBillingWidgets() {
  return http.get(apiEndpoint + "/stats");
}

export function getBillingGraph() {
  return http.get(apiEndpoint + "/stats/graph");
}

export function getRemittance(remittanceId) {
  return http.get(remittanceUrl(remittanceId));
}
