import React from "react";
import Layout from "../../components/common/Layout";
import MainRates from "../../container/Dashboard/MainRates";

const Rates = () => {
  return (
    <Layout activeItem={2}>
      <MainRates />
    </Layout>
  );
};

export default Rates;
