import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

const StackChart = ({ data, labels }) => {
  const chartOptions = {
    series: data,
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        dataLabels: {
          enabled: false
        },

        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: false,
            delay: 50
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 15,
          dataLabels: {
            enabled: false
          }
        }
      },
      xaxis: {
        type: "text",
        categories: labels
      },
      legend: {
        position: "right",
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }
  };
  return (
    <div className="w-full">
      <ReactApexChart
        options={{
          ...chartOptions.options,
          colors: ["#7F56D9", "#9E77ED", "#D6BBFB"]
        }}
        series={chartOptions.series}
        type="bar"
        height="350"
      />
    </div>
  );
};

export default StackChart;
