import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const BillingChart = ({ data, labels }) => {
  const [state, setState] = useState({
    series: data,
    options: {
      colors: ["#714CC1", "#B692F6", "#53389E"],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: labels,
      },
    },
  });
  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="line"
      height={350}
    />
  );
};

export default BillingChart;
