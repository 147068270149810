import React from "react";
import { FiSearch } from "react-icons/fi";

const InputField = ({ extraClasses, Icon, ...rest }) => {
  return (
    <div
      className={`p-2 flex ${extraClasses}`}
      style={{ border: "1px solid #D0D5DD", borderRadius: "8px" }}
    >
      {Icon && <Icon size="24" className="text-xl text-gray-500 mr-2" />}
      <input
        className="appearance-none outline-none w-full bg-transparent"
        {...rest}
      />
    </div>
  );
};

export default InputField;
