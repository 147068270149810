import AppSwitch from "../../components/common/tables/AppSwitch";

export const userColumns = [
  // { field: "id", headerName: "ID", width: 70 },
  {
    field: "info",
    headerName: "Nombre",
    flex: 1,
    minWidth: 230,
    renderCell: (params) => {
      return (
        <div className="flex items-center">
          <AppSwitch id={params.row.id} included={params.row.info.included} />
          <span className="text-sm font-medium text-black">
            {params.row.info.name}
          </span>
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Estado del usuario",
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      let color = "text-green-500";
      let bgColor = "bg-green-100";
      let indicatorColor = "bg-green-500";
      switch (params.row.status) {
        case "Completo":
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
          break;
        case "Incompleto":
          color = "text-red-500";
          bgColor = "bg-red-100";
          indicatorColor = "bg-red-500";
          break;

        default:
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
      }
      return (
        <div
          className={`${bgColor} ${color} px-2 py-1 text-xs rounded-full flex justify-center items-center`}
        >
          <div
            className={`w-1.5 h-1.5 rounded-full mr-2 ${indicatorColor} `}
          ></div>
          {params.row.status}
        </div>
      );
    },
  },
  {
    field: "method",
    headerName: "Metodo de cobro",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      return (
        <div className="flex items-center">
          <img
            className="w-8"
            src={
              "https://images.assetsdelivery.com/compings_v2/oksananahorna/oksananahorna1803/oksananahorna180300197.jpg"
            }
          />
          {params.row.method}
        </div>
      );
    },
  },
  {
    field: "amount",
    headerName: "Importe",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      return <div className={`text-gray-500 text-sm`}>{params.row.amount}</div>;
    },
  },
];

//temporary data
export const userRows = [
  {
    id: 1,
    info: { name: "Cristina Arranz", included: true },
    status: "Completo",
    method: "····7687",
    amount: "98€",
  },
];
