import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import StackChart from "../../components/charts/StackChart";
import Widget from "../../components/common/Widget";
import { AiOutlineCloudDownload, AiOutlinePlus } from "react-icons/ai";
import AppButton from "../../components/common/AppButton";
import InputField from "../../components/common/InputField";
import { FiSearch } from "react-icons/fi";
import AppDatePicker from "../../components/common/AppDatePicker";
import AppTable from "../../components/common/tables/AppTable";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getClients, getClientsWidgets } from "../../services/clientService";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import moment from "moment";
import { CSVLink } from "react-csv";
import AppAlert from "../../components/form/AppAlert";
import { getHistoryGraphData } from "../../services/clientHistoryService";
import Button from "../../components/common/Button";
import EquipementTable from "../../components/common/tables/EquipementTable";
import { getUsers } from "../../services/userService";

const MainEquipement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [success, setSuccess] = useState("");

  useEffect(() => {
    getAllUsers();

    return () => {};
  }, []);

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const { data } = await getUsers();
      console.log("Users: ", data);
      setUsers(
        data.map((user) => ({
          id: user._id,
          fullName: user.fullName,
          email: user.email,
          isAdmin: user?.isAdmin ? true : false,
        }))
      );
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className="p-4">
      {/* Success Alert */}
      {success && (
        <div className="fixed bottom-5 left-3 right-3 z-50">
          <AppAlert type="success" message={success} />
        </div>
      )}
      {/* Title Container */}
      <div className="">
        <h1 className="text-3xl font-medium">Equipo</h1>
        <p className="text-sm text-gray-500">
          Maneja a tu equipo y sus accesos
        </p>
      </div>

      {/* Section 1 Starts */}
      <section className="flex gap-8 mt-[60px]">
        {/* Left */}
        <div className="flex-[1]">
          <h4 className="text-gray-700 font-medium text-sm leading-5">
            Invita a miembros a tu equipo
          </h4>
          <p className="text-gray-500 font-normal text-sm leading-5">
            Asigna roles para restringir acceso al contenido del dashboard
          </p>
        </div>
        {/* Right */}
        <div className="flex-[3] flex flex-col gap-4">
          {/* Top */}
          <div className="">
            <InputField Icon={AiOutlineMail} placeholder="mail@ejemplo.com" />
          </div>
          {/* Bottom */}
          <div className="flex justify-end">
            <button className="flex gap-[10px] px-4 py-[10px] bg-[#7F56D9] text-white rounded-lg">
              <HiOutlineMail className="text-[24px]" />
              <div>Enviar invitación</div>
            </button>
          </div>
        </div>
      </section>
      {/* Section 1 Ends */}

      <hr className="my-6 " />

      {/* Section 2 Starts */}
      <section className="flex gap-8 mt-[60px]">
        {/* Left */}
        <div className="flex-[1]">
          <h4 className="text-gray-700 font-medium text-sm leading-5">
            Miembros del equipo
          </h4>
          <p className="text-gray-500 font-normal text-sm leading-5">
            Listado de miembros que pertenecen al equipo
          </p>
        </div>
        {/* Right */}
        <div className="flex-[3] flex flex-col gap-4">
          {/* Table */}
          <div className="mt-2">
            <EquipementTable
              users={users}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
            />
          </div>
        </div>
      </section>
      {/* Section 2 Ends */}
    </div>
  );
};

export default MainEquipement;
