import { Alert } from "@mui/material";
import React, { useState } from "react";

const AppAlert = ({ type = "success", message, position }) => {
  const [close, setClose] = useState(false);

  const RenderAlert = () => {
    return (
      <Alert
        className={`w-full ${position} `}
        severity={type}
        style={{ fontWeight: 500, color: type === "success" && "#027A48" }}
        onClose={() => {
          setClose(true);
        }}
      >
        {message}
      </Alert>
    );
  };
  return <div>{!close && <RenderAlert />}</div>;
};

export default AppAlert;
