import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ status }) => {
  const up = [10, 70, 20, 90];
  const down = [90, 20, 70, 10];
  let data = [];
  let color = "#12B76A";

  if (status === "up") {
    data = up;
    color = "#12B76A";
  } else {
    data = down;
    color = "#F04438";
  }
  const chartOptions = {
    series: [
      {
        data: data,
      },
    ],
    options: {
      colors: [color],
      chart: {
        background: "transparent",
        sparkline: {
          enabled: true,
        },
      },

      tooltip: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },

      stroke: {
        curve: "smooth",
        width: 4,
      },
      // tooltip: {
      //   theme: "dark",
      //   fixed: {
      //     enabled: false,
      //   },
      //   x: {
      //     show: false,
      //   },
      //   y: {
      //     title: "Total Order",
      //   },
      //   marker: {
      //     show: false,
      //   },
      // },
      legend: {
        position: "top",
      },
      grid: {
        show: false,
      },
    },
  };

  return (
    <Chart
      options={{
        ...chartOptions.options,
      }}
      series={chartOptions.series}
      type="line"
      height="100"
      // width={128}
      className="w-full"
    />
  );
};

export default LineChart;
