import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import StackChart from "../../components/charts/StackChart";
import Widget from "../../components/common/Widget";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getClientsGraphData,
  getClientsWidgets
} from "../../services/clientService";
import { labels } from "../../assets/data/stackChartSource";
import { getHistoryGraphData } from "../../services/clientHistoryService";

const MainSection = () => {
  const [selectedTime, setSelectedTime] = React.useState("m");
  const [widgets, setWidgets] = useState([]);
  const [allWidgets, setAllWidgets] = useState([]);
  const [graphAllData, setGraphAllData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const graphButtons = ["Usuarios activos", "Altas", "Bajas"];

  const handleSelectedTime = (event, newSelectedTime) => {
    if (newSelectedTime !== null) {
      setSelectedTime(newSelectedTime);
      // console.log(newSelectedTime);
      if (newSelectedTime === "m") {
        setWidgets(allWidgets[0]);
      } else if (newSelectedTime === "30d") {
        setWidgets(allWidgets[1]);
      } else if (newSelectedTime === "7d") {
        setWidgets(allWidgets[2]);
      } else if (newSelectedTime === "24h") {
        setWidgets(allWidgets[3]);
      }
    }
  };

  useEffect(() => {
    getGraphData();
    getWidgets();

    return () => {};
  }, []);

  const getGraphData = async () => {
    setLoading(true);
    try {
      const { data } = await getHistoryGraphData();
      setGraphAllData(data[0][0]);
      setGraphData(data[0][0][0]);
      setGraphLabels(data[0][1]);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }
  };

  const getWidgets = async () => {
    try {
      const { data } = await getClientsWidgets();
      setAllWidgets(data);
      setWidgets(data[0]);
      // console.log(users);
    } catch (ex) {
      console.log(ex.response.error);
    }
    setLoading(false);
  };

  // console.log("Render");

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className="">
      {/* Title Container */}
      <div className="">
        <h1 className="text-3xl font-medium">Dashboard</h1>
      </div>

      {/* Days Selection and Date picker Container  */}
      {/* <div className="flex mt-4">
        <ToggleButtonGroup
          value={selectedTime}
          exclusive
          onChange={handleSelectedTime}
          aria-label="text selectedTime"
          className="h-8"
        >
          <ToggleButton value="m" aria-label="12 month">
            <span className="lowercase">12m</span>
          </ToggleButton>
          <ToggleButton value="30d" aria-label="30 days">
            <span className="lowercase">30d</span>
          </ToggleButton>
          <ToggleButton value="7d" aria-label="7 days">
            <span className="lowercase">7d</span>
          </ToggleButton>
          <ToggleButton value="24h" aria-label="24 hours">
            <span className="lowercase">24h</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </div> */}

      {/* Main Wigets Container */}
      <div className="grid mt-4 gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {widgets.map((user) => (
          <Widget key={user.id} info={user} />
        ))}
      </div>

      {/* Graph Container */}
      <div className="shadow p-4 mt-4">
        <h3 className="text-lg font-medium">Volumen de usuarios</h3>
        <div className="flex gap-2 mt-4">
          {graphButtons.map((item, index) => (
            <button
              key={index}
              className="px-3 py-2 font-medium"
              onClick={() => {
                setSelectedIndex(index);
                setGraphData(graphAllData[index]);
              }}
              style={{
                background: selectedIndex === index ? "#F9F5FF" : "transparent",
                color: selectedIndex === index ? "#6941C6" : "#667085",
                borderRadius: "6px"
              }}
            >
              {item}
            </button>
          ))}
        </div>
        <StackChart data={[graphData]} labels={graphLabels} />
      </div>
    </div>
  );
};

export default MainSection;
