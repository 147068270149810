import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bgImage from "../assets/images/bg2.jpg";
import AppButton from "../components/common/AppButton";
import Input from "../components/common/Input";
import * as Yup from "yup";
import { register } from "../services/userService";
import AppAlert from "../components/form/AppAlert";

const Register = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (user) => {
    setError("");
    try {
      await register(user);
      alert("User Registered successfully.");
      setError("");
      navigate("/login");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        // alert(ex.response.data);
        setError(ex.response.data);
      }
    }
  };

  const validate = Yup.object({
    fullName: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm password is required"),
  });

  return (
    <div className="h-full w-full flex flex-col-reverse md:flex-row ">
      {error && <AppAlert type="error" message={error} />}
      {/* Left Container */}
      <div className="flex-1 mt-8">
        <div className="flex flex-col h-full">
          {/* Top Container */}
          <div className="h-4/5 flex flex-col items-center  w-full">
            <div className="w-4/5 md:w-3/5">
              <h1 className="text-3xl capitalize">SignUp</h1>
              <p className="text-sm text-gray-500 mt-2">
                Introduce los detalles para entrar al panel
              </p>
              <Formik
                initialValues={{
                  fullName: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={validate}
                onSubmit={(values) => {
                  console.log(values);
                  handleSubmit(values);
                }}
              >
                {(formik) => (
                  <Form className="flex flex-col">
                    <Input
                      label="Full Name"
                      name="fullName"
                      type="text"
                      placeholder="Enter your full name"
                    />
                    <Input
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                    />
                    <Input
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="password..."
                    />
                    <Input
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      placeholder="confirm password..."
                    />

                    <AppButton
                      disabled={!formik.isValid}
                      type="submit"
                      title="Sign Up"
                      extraClasses="mt-6"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {/* Bottom Container */}
          <div className="h-1/5 relative">
            <p className="hidden md:block absolute bottom-4 left-8 text-gray-400">
              © Cris4life S.L 2022
            </p>
          </div>
        </div>
      </div>
      {/* Right Container */}
      <div className="md:flex-1">
        <img
          src={bgImage}
          className="w-full h-16 object-cover md:h-full"
          alt=""
        />
      </div>
    </div>
  );
};

export default Register;
