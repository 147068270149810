import React, { useEffect, useState } from "react";
import AppButton from "../../components/common/AppButton";
import Icon from "../../components/common/Icon";
import Input from "../../components/common/Input";
import BackToLogin from "../../components/form/BackToLogin";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { resetPassword, verifyToken } from "../../services/userService";
import AppAlert from "../../components/form/AppAlert";

const NewPassword = () => {
  const location = useLocation();
  const [invalidUser, setInvalidUser] = useState("");
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { token, id } = queryString.parse(location.search);

  const validate = Yup.object({
    password: Yup.string()
      .min(8, "Debe tener al menos 8 caracteres.")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm password is required"),
  });

  const verify = async (token, id) => {
    try {
      setBusy(true);
      const { data } = await verifyToken(token, id);
      console.log(data);
    } catch (ex) {
      if (ex?.response?.data) {
        const { data } = ex.response;
        console.log(data);
        if (!data.success) return setInvalidUser(data);
        return console.log(ex.response.data);
      }
      console.log(ex);
    }
    setBusy(false);
  };

  useEffect(() => {
    verify(token, id);
  }, []);

  const changePassword = async (password) => {
    setError("");
    try {
      await resetPassword(token, id, password);
      navigate("/password-changed", { replace: true });
    } catch (error) {
      if (error.response) {
        setError(error.response.data);
      }
    }
  };

  if (invalidUser) {
    return (
      <div className="max-w-screen-sm m-auto pt-40">
        <h1 className="text-center text-3xl text-gray-500 mb-3">
          {invalidUser}
        </h1>
      </div>
    );
  }

  if (busy) {
    return (
      <div className="max-w-screen-sm m-auto pt-40">
        <h1 className="text-center text-3xl text-gray-500 mb-3">
          Wait for a moment verifying reset token...
        </h1>
      </div>
    );
  }
  return (
    <div className="flex flex-col h-full w-full">
      {error && <AppAlert type="error" message={error} position="" />}

      <div className="flex flex-col w-4/5 items-center self-center mt-10 mx-4 md:w-96">
        <Icon darkColor="#FEF0C7" lightColor="#FFFAEB" />
        <h1 className="text-3xl mt-8 text-center">Nueva contraseña</h1>
        <p className="text-gray-400 mt-3 text-center max-w-xs">
          Su nueva contraseña debe ser diferente a las contraseñas utilizadas
          anteriormente.
        </p>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={validate}
          onSubmit={(values) => {
            const { password } = values;
            changePassword(password);
          }}
        >
          {(formik) => (
            <Form action="" className="form-vertical form w-full mt-8">
              <Input
                type="password"
                name="password"
                label="Contraseña"
                placeholder="New Password"
              />

              <Input
                name="confirmPassword"
                type="password"
                label="Nueva contraseña"
                placeholder="Confirm Password"
              />

              <AppButton
                type="submit"
                title="Resetear contraseña"
                extraClasses="mt-6"
                disabled={!formik.isValid}
              />
            </Form>
          )}
        </Formik>

        <Link to="/login" className="mt-8">
          <BackToLogin />
        </Link>
      </div>
    </div>
  );
};

export default NewPassword;
