import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import bgImage from "../assets/images/bg.jpg";
import AppButton from "../components/common/AppButton";
import Input from "../components/common/Input";
import * as Yup from "yup";
import auth from "../services/authService";
import { useNavigate } from "react-router-dom";
import AppAlert from "../components/form/AppAlert";

const Login = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
  });

  const handleSubmit = async (user) => {
    setError("");
    setLoading(true);
    try {
      const { email, password } = user;

      await auth.login(email, password);
      // alert("Login successfully.");
      setError("");
      navigate("/", { replace: true });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        // alert(ex.response.data);
        setError(ex.response.data);
      }
    }
    setLoading(false);
  };

  console.log(process.env.REACT_APP_API_URL);

  return (
    <div className="h-screen w-full flex flex-col-reverse md:flex-row">
      {error && <AppAlert type="error" message={error} position="absolute" />}
      {/* Left Container */}
      <div className="flex-1 mt-8">
        <div className="flex flex-col h-full">
          {/* Top Container */}
          <div className="h-4/5 flex flex-col items-center justify-center w-full">
            <div className="w-4/5 md:w-3/5">
              <h1 className="text-3xl font-semibold">Iniciar sesión</h1>
              <p className="text-base text-gray-500 mt-2">
                Introduce los detalles para entrar al panel
              </p>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validate}
                onSubmit={(values) => {
                  console.log(values);
                  handleSubmit(values);
                }}
              >
                {(formik) => (
                  <Form className="flex flex-col">
                    <Input
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                    />
                    <Input
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="password..."
                    />

                    {/* Remember password Section */}
                    <div className="flex mt-8 justify-between">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="remember"
                          color="purple"
                          className="text-purple-500 bg-purple-500"
                        />
                        <label
                          htmlFor="remember"
                          className="ml-2 text-sm text-gray-700 font-medium"
                        >
                          Recordar contraseña
                        </label>
                      </div>
                      <Link to="/forget-password">
                        <p className="text-sm text-gray-700 font-medium cursor-pointer">
                          Olvidé mi contraseña
                        </p>
                      </Link>
                    </div>
                    {/* <Link to="/" className="mt-4">
                    </Link> */}
                    <AppButton
                      disabled={!formik.isValid}
                      type="submit"
                      title={loading ? "Please wait..." : "Iniciar sesión"}
                      extraClasses="mt-4"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {/* Bottom Container */}
          <div className="h-1/5 relative">
            <p className="absolute bottom-4 left-8 text-gray-400">
              © Cris4life S.L 2022
            </p>
          </div>
        </div>
      </div>
      {/* Right Container */}
      <div className="md:flex-1">
        <img
          src={bgImage}
          className="w-full h-16 object-cover md:h-full"
          alt=""
        />
      </div>
    </div>
  );
};

export default Login;
