import { Component, useEffect } from "react";
import auth from "../../services/authService";
import { useNavigate } from "react-router-dom";

import React from "react";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    auth.logout();
    navigate("/login", { replace: true });
  }, []);
  return null;
};

export default Logout;
