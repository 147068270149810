import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import * as Yup from "yup";
import FormikInput from "../../components/form/FormikInput";
import AppButton from "../../components/common/AppButton";
import { Form, Formik } from "formik";
import FormikTextArea from "../../components/form/FormikTextArea";
import {
  addPackage,
  getPackage,
  updatePackage,
} from "../../services/packageService";
import FormAlert from "../../components/form/FormAlert";
import { FiCheckCircle } from "react-icons/fi";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import AppAlert from "../../components/form/AppAlert";

const EditRate = () => {
  const [newPackage, setNewPackage] = useState({});
  const [loadedPackage, setLoadedPackage] = useState({});
  const [alertVisible, setAlertVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [response, setResponse] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    populateForm();

    return () => {};
  }, []);

  const accept = async () => {
    setLoading(true);
    setResponse({});
    try {
      await updatePackage(params.id, newPackage);
      localStorage.setItem("success", "Tu tarifa ha sido actualizada");
      navigate("/rates", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.response.data, { variant: "error" });

      // setResponse({ error: err.response.data });
    }
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const reject = () => {
    console.log("Reject");
  };

  const populateForm = async () => {
    try {
      const { data } = await getPackage(params.id);
      // console.log(data);
      setLoadedPackage(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        navigate("/not-found", { replace: true });
      }
    }
  };

  const validateFirst = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    credit: Yup.number().required("Required"),
    amount: Yup.number().required("Required"),
  });

  return (
    <Layout activeItem={2}>
      {/* Response Alert */}
      <div className="mb-4">
        {response?.error && <AppAlert type="error" message={response?.error} />}
        {response?.success && (
          <AppAlert type="success" message={response?.success} />
        )}
      </div>

      {alertVisible && (
        <FormAlert
          agree={accept}
          disagree={reject}
          open={alertVisible}
          setOpen={setAlertVisible}
          message="¿Quieres actualizar esta tarifa?"
          acceptTitle="Actualizar"
          rejectTitle="Cancelar"
          IconComponent={FiCheckCircle}
        />
      )}

      {loading && <LinearProgress color="secondary" />}

      {/* Title Container */}
      <div className="mb-5">
        <h1 className="text-3xl font-medium">Nueva tarifa</h1>
      </div>

      {/* Tabs Container */}
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: loadedPackage.name || "",
            description: loadedPackage.desc || "",
            credit: loadedPackage.credit || "",
            amount: loadedPackage.monthlyPrice || "",
          }}
          validationSchema={validateFirst}
          onSubmit={(values) => {
            setNewPackage({
              name: values.name,
              desc: values.description,
              credit: values.credit,
              monthlyPrice: values.amount,
            });
            setAlertVisible(true);
            // console.log("Values: ", values);
            // handleSubmit(values);
          }}
        >
          {(formik) => (
            <Form>
              {/* Heading */}
              <div className="flex flex-col-reverse gap-4 lg:flex-row justify-between">
                <div>
                  <h3 className="text-lg font-medium">
                    Información sobre la tarifa
                  </h3>
                  <p className="text-sm text-gray-500">
                    Añade todos los datos relacionados con la tarifa
                  </p>
                </div>
                <div className="flex gap-6">
                  <AppButton
                    title="Cancelar"
                    color="black"
                    bgColor="white"
                    onClick={() => {}}
                  />
                  <AppButton
                    title="Guardar"
                    disabled={!formik.isValid}
                    type="submit"
                  />
                </div>
              </div>
              <hr className="my-5 " />

              {/* Name */}
              <div className="flex flex-col lg:flex-row gap-4">
                <div className="w-full lg:w-1/3">
                  <label className="text-sm font-medium">
                    Nombre de la tarifa
                  </label>
                  <p className="text-sm text-gray-500">
                    El nombre que aparecerá en tu panel
                  </p>
                </div>
                <div className="flex-1">
                  <FormikInput name="name" placeholder="Nombre de la tarifa" />
                </div>
              </div>
              <hr className="my-5 " />

              {/* Description */}
              <div className="flex flex-col lg:flex-row gap-4">
                <div className="w-full lg:w-1/3">
                  <label className="text-sm font-medium">Descripción</label>
                  <p className="text-sm text-gray-500">
                    Añade información adicional sobre tu tarifa
                  </p>
                </div>
                <div className="flex-1 relative">
                  <FormikTextArea
                    name="description"
                    placeholder="Descripción..."
                  />
                  <label className="text-sm absolute text-red-600 -bottom-5 left-0">
                    {formik.errors.description}
                  </label>
                </div>
              </div>
              <hr className="my-5 " />

              {/* Credit */}
              <div className="flex flex-col lg:flex-row gap-4">
                <div className="w-full lg:w-1/3">
                  <label className="text-sm font-medium">
                    Número de créditos mensuales
                  </label>
                  <p className="text-sm text-gray-500">
                    Indica los créditos mensuales que tendrán los socios
                  </p>
                </div>
                <div className="flex-1">
                  <FormikInput
                    name="credit"
                    placeholder="Número de créditos mensuales"
                    type="number"
                  />
                </div>
              </div>
              <hr className="my-5 " />

              {/* Amount */}
              <div className="flex flex-col lg:flex-row gap-4">
                <div className="w-full lg:w-1/3">
                  <label className="text-sm font-medium">Importe</label>
                  <p className="text-sm text-gray-500">
                    Indica los créditos mensuales que tendrán los socios
                  </p>
                </div>
                <div className="flex-1">
                  <FormikInput
                    name="amount"
                    placeholder="Importe"
                    type="number"
                  />
                </div>
              </div>
              <hr className="my-5 " />

              {/* Buttons */}
              <div className="flex justify-end flex-col lg:flex-row gap-4">
                <div className="flex gap-6">
                  <AppButton
                    title="Cancelar"
                    color="black"
                    bgColor="white"
                    // onClick={() => console.log(formik)}
                  />
                  <AppButton
                    type="submit"
                    title="Guardar"
                    disabled={!formik.isValid}
                    // onClick={() => setValue("2")}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default EditRate;
