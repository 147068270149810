import React from "react";
import { Routes, Route } from "react-router-dom";
import EmailSent from "./pages/ForgetPassword/EmailSent";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NewPassword from "./pages/ForgetPassword/NewPassword";
import PasswordChanged from "./pages/ForgetPassword/PasswordChanged";
import Register from "./pages/Register";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Logout from "./components/common/Logout";
import Billing from "./pages/billing/Billing";
import Support from "./pages/Support";
import Settings from "./pages/Settings";
import Users from "./pages/users/Users";
import AddUser from "./pages/users/AddUser";
import EditUser from "./pages/users/EditUser";
import NewConsignment from "./pages/billing/NewConsignment";
import PendingRemittance from "./pages/billing/PendingRemittance";
import { SnackbarProvider } from "notistack";
import Rates from "./pages/rates/Rates";
import AddRate from "./pages/rates/AddRate";
import EditRate from "./pages/rates/EditRate";
import Equipement from "./pages/equipement/Equipement";
import { createTheme, ThemeProvider } from "@mui/material";
import AdminProtectedRoute from "./components/common/AdminProtectedRoute";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#6941C6", // very red
      },
      secondary: {
        main: "#6941C6", // very cyan
      },
    },
  });
  // console.log(process.env.REACT_APP_API_URL);
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-user"
              element={
                <ProtectedRoute>
                  <AddUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="users/:id"
              element={
                <ProtectedRoute>
                  <EditUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="rates"
              element={
                <ProtectedRoute>
                  <Rates />
                </ProtectedRoute>
              }
            />
            <Route
              path="rates/new"
              element={
                <ProtectedRoute>
                  <AddRate />
                </ProtectedRoute>
              }
            />
            <Route
              path="rates/:id"
              element={
                <ProtectedRoute>
                  <EditRate />
                </ProtectedRoute>
              }
            />
            <Route
              path="billing"
              element={
                <AdminProtectedRoute>
                  <Billing />
                </AdminProtectedRoute>
              }
            />
            <Route
              path="billing/pending-remittance/:id"
              element={
                <AdminProtectedRoute>
                  <PendingRemittance />
                </AdminProtectedRoute>
              }
            />
            <Route
              path="new-consignment"
              element={
                <AdminProtectedRoute>
                  <NewConsignment />
                </AdminProtectedRoute>
              }
            />
            <Route
              path="equipement"
              element={
                <ProtectedRoute>
                  <Equipement />
                </ProtectedRoute>
              }
            />
            <Route
              path="support"
              element={
                <ProtectedRoute>
                  <Support />
                </ProtectedRoute>
              }
            />
            <Route
              path="settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />

            <Route path="login" element={<Login />} />
            <Route
              path="register"
              element={
                <ProtectedRoute>
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="email-sent" element={<EmailSent />} />
            <Route path="new-password" element={<NewPassword />} />
            <Route path="password-changed" element={<PasswordChanged />} />
            <Route path="*" element={<p>Page Not Found: 404!</p>} />
            <Route path="/not-found" element={<p>Page Not Found: 404!</p>} />
          </Route>
        </Routes>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
