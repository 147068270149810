import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import { FiUser } from "react-icons/fi";
import RoundedIcon from "../../components/form/RoundedIcon";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { TabList } from "@mui/lab";
import * as Yup from "yup";
import FormikInput from "../../components/form/FormikInput";
import FileUploader from "../../components/form/FileUploader";
import AppButton from "../../components/common/AppButton";
import RadioCard from "../../components/form/RadioCard";
import { Form, Formik } from "formik";
import FormikDatePicker from "../../components/form/FormikDatePicker";
import FormikTextArea from "../../components/form/FormikTextArea";
import { AiOutlineCloseCircle, AiOutlineMail } from "react-icons/ai";
import { BsLayers, BsTelephone } from "react-icons/bs";
import { GrAddCircle, GrLocation } from "react-icons/gr";
import { getPackages } from "../../services/packageService";
import FormAlert from "../../components/form/FormAlert";
import { FiCheckCircle, FiCreditCard } from "react-icons/fi";
import { IoIosAddCircleOutline } from "react-icons/io";
import { addClient } from "../../services/clientService";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import FormData from "form-data";
import { LinearProgress } from "@mui/material";
import CheckboxCard from "../../components/form/ChechboxCard";
import SimpleCheckbox from "../../components/form/SimpleCheckbox";
import { calculateControlDigits } from "../../utils/helper";
import { ibanIsValid, ibanToBic } from "iban-to-bic";

const AddUser = () => {
  const [value, setValue] = React.useState("1");
  const [newUser, setNewUser] = useState({});
  const [packages, setPackages] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [alertVisible, setAlertVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [tution, setTution] = useState(false);
  const [reduced, setReduced] = useState(false);
  const [reducedRate, setReducedRate] = useState(null);
  const [secondRateId, setSecondRateId] = useState("");

  const tutionRate = {
    _id: "112233",
    name: "Mátricula",
    monthlyPrice: "20",
    desc: "Este pago solo se cobra cuando el usuario se apunta al centro",
  };

  const handleTution = () => {
    setTution(!tution);
    console.log(!tution);
  };
  const handleReduced = () => {
    setReduced(!reduced);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPackages();

    return () => {};
  }, []);

  const accept = async () => {
    const formData = new FormData();
    formData.append("firstName", newUser.firstName);
    formData.append("lastName", newUser.lastName);
    formData.append("nif", newUser.nif);
    formData.append("email", newUser.email);
    formData.append("phoneNo", newUser.phoneNo);
    formData.append("dateOfBirth", newUser.dateOfBirth);
    formData.append("direction", newUser.direction);
    formData.append("profileImage", profileImage);
    formData.append("observation", newUser.observation);
    formData.append("enrollmentDate", newUser.enrollmentDate);
    formData.append("packageId", newUser.packageId);
    formData.append("accountNo", newUser.accountNo);
    formData.append("BIC", newUser.BIC);

    if (newUser.firstRateStartDate)
      formData.append("firstRateStartDate", newUser.firstRateStartDate);
    if (newUser.firstRateEndDate)
      formData.append("firstRateEndDate", newUser.firstRateEndDate);
    if (tution) {
      formData.append("tutionFee", 20);
    } else {
      formData.append("tutionFee", 0);
    }

    console.log("User: ", newUser);

    if (reducedRate) {
      formData.append("secondRateId", secondRateId);
      formData.append("secondRateStartDate", newUser.secondRateStartDate);
      if (newUser.secondRateEndDate)
        formData.append("secondRateEndDate", newUser.secondRateEndDate);
    } else {
      formData.append("secondRateId", "");
    }
    setLoading(true);
    try {
      await addClient(formData);
      // console.log("User Added");
      // enqueueSnackbar("User Added Successfully!", { variant: "success" });
      localStorage.setItem("success", "Tu usuario ha sido guardado");
      navigate("/users", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.response.data, { variant: "error" });
    }
    setLoading(false);
  };

  const reject = () => {
    console.log("Reject");
  };

  const loadPackages = async () => {
    try {
      const { data } = await getPackages();
      setPackages(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const validateFirst = Yup.object({
    firstName: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    lastName: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    nif: Yup.string().required("N.I.F is required"),
    email: Yup.string().email().required("Email is required"),
    phoneNo: Yup.string()
      .min(9, "Must be 9 digits or more")
      .required("Telephone Number is required"),
    dateOfBirth: Yup.date().required("Date is required").nullable(),
    direction: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Direction is required"),
    profileImage: Yup.string(),
    observation: Yup.string(),
  });

  const validateSecond = Yup.object({
    enrollmentDate: Yup.date().required("Date is required").nullable(),
    firstRateStartDate: Yup.date()
      .required("Rate Start Date is required")
      .nullable(),
    secondRateStartDate: reducedRate
      ? Yup.date().required("Start Date is required").nullable()
      : Yup.date().nullable(),
    secondRateEndDate: Yup.date().nullable(),
    packageId: Yup.string().required("Please select rate."),
  });

  const validateThird = Yup.object({
    accountNo: Yup.string()
      .test("validate-iban", "Invalid IBAN", function (value) {
        // Call the BICFromIBAN.validateIBAN function to check if the IBAN is correct
        return ibanIsValid(value);
      })
      .required("Account number is required"),
    BIC: Yup.string().required("Swift Code Required"),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePackages = (event) => {
    console.log(event.target.value);
  };

  const calculateBIC = (formik) => {
    const { accountNo } = formik.values;
    const BIC = ibanToBic(accountNo);
    formik.setFieldValue("BIC", BIC);
  };

  return (
    <Layout activeItem={3}>
      {alertVisible && (
        <FormAlert
          agree={accept}
          disagree={reject}
          open={alertVisible}
          setOpen={setAlertVisible}
          message="¿Quieres guardar este usuario?"
          acceptTitle="Guardar"
          rejectTitle="Cancelar"
          IconComponent={FiCheckCircle}
        />
      )}

      {loading && <LinearProgress color="secondary" />}

      {/* Title Container */}
      <div className="flex items-center">
        <RoundedIcon Icon={FiUser} />
        <h1 className="text-3xl font-medium ml-2">Nuevo usuario</h1>
      </div>

      {/* Tabs Container */}
      <div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              // onChange={handleChange}
              aria-label="lab API tabs example"
              className="text-purple-500 border-bottom-purple-500"
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#A855F7",
                },
              }}
            >
              <Tab label="Información" value="1" />
              <Tab label="Tarifa" value="2" />
              <Tab label="Facturación" value="3" />
            </TabList>
          </Box>
          {/* First Step */}
          <TabPanel value="1">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                nif: "",
                email: "",
                phoneNo: "",
                dateOfBirth: null,
                direction: "",
                profileImage: "",
                observation: "",
              }}
              validationSchema={validateFirst}
              onSubmit={(values) => {
                setNewUser(values);
                setValue("2");
                console.log(values);
                console.log(profileImage);
                // handleSubmit(values);
              }}
            >
              {(formik) => (
                <Form>
                  {/* Heading */}
                  <div className="flex flex-col-reverse gap-4 lg:flex-row justify-between">
                    <div>
                      <h3 className="text-lg font-medium">Datos personales</h3>
                      <p className="text-sm text-gray-500">
                        Añade todos los datos personales del nuevo usuario
                      </p>
                    </div>
                    <div className="flex gap-6">
                      <AppButton
                        title="Cancelar"
                        color="black"
                        bgColor="white"
                        onClick={() => {
                          console.log(newUser);
                        }}
                      />
                      <AppButton
                        title="Guardar"
                        disabled={!formik.isValid}
                        type="submit"
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* First Name and Last Name */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <label className="text-sm w-full lg:w-1/4 font-medium">
                      Nombre y apellido
                    </label>
                    <div className="flex flex-1 gap-8">
                      <FormikInput name="firstName" placeholder="First Name" />
                      <FormikInput name="lastName" placeholder="Last Name" />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* N.I.F */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <label className="text-sm w-full lg:w-1/4  font-medium">
                      N.I.F.
                    </label>
                    <div className="flex-1">
                      <FormikInput
                        name="nif"
                        placeholder="N.I.F"
                        Icon={BsLayers}
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Email */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <label className="text-sm w-full lg:w-1/4  font-medium">
                      Email
                    </label>
                    <div className="flex-1">
                      <FormikInput
                        Icon={AiOutlineMail}
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Phone Number */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <label className="text-sm w-full lg:w-1/4  font-medium">
                      Teléfono
                    </label>
                    <div className="flex-1">
                      <FormikInput
                        Icon={BsTelephone}
                        name="phoneNo"
                        placeholder="Telephone No..."
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Date of Birth */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <label className="text-sm w-full lg:w-1/4  font-medium">
                      Fecha de nacimiento
                    </label>
                    <div className="flex-1">
                      <FormikDatePicker
                        formik={formik}
                        label="Select Date"
                        name="dateOfBirth"
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Direction */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <label className="text-sm w-full lg:w-1/4  font-medium">
                      Dirección
                    </label>
                    <div className="flex-1">
                      <FormikInput
                        name="direction"
                        placeholder="Dirección"
                        Icon={GrLocation}
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Profile Picture */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <label className="text-sm w-full lg:w-1/4  font-medium">
                      Foto de perfil
                    </label>
                    <div className="flex-1 relative">
                      <FileUploader
                        formik={formik}
                        setFile={setProfileImage}
                        fieldName="profileImage"
                      />
                      <p className="absolute text-sm text-red-500 -mt-4">
                        {formik.errors.profileImage}
                      </p>
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Observation */}
                  <div className="flex flex-col lg:flex-row gap-4 ">
                    <div className="w-full lg:w-1/4 flex flex-col">
                      <label className="text-sm  font-medium ">
                        Observaciones
                      </label>
                      <label className="text-sm text-gray-500">
                        Necesidades especiales
                      </label>
                    </div>
                    <div className="flex-1">
                      <FormikTextArea
                        name="observation"
                        placeholder="Observation..."
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Buttons */}
                  <div className="flex justify-end flex-col lg:flex-row gap-4">
                    <div className="flex gap-6">
                      <AppButton
                        title="Cancelar"
                        color="black"
                        bgColor="white"
                        onClick={() => console.log(formik)}
                      />
                      <AppButton
                        type="submit"
                        title="Guardar"
                        disabled={!formik.isValid}
                        // onClick={() => setValue("2")}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </TabPanel>

          {/* Second Step */}
          <TabPanel value="2">
            <Formik
              initialValues={{
                enrollmentDate: null,
                secondRateStartDate: null,
                secondRateEndDate: null,
                firstRateStartDate: null,
                firstRateEndDate: null,
                packageId: "",
              }}
              validationSchema={validateSecond}
              onSubmit={(values) => {
                setNewUser({ ...newUser, ...values });
                setValue("3");
                // console.log(newUser);
                console.log(values);
              }}
            >
              {(formik) => (
                <Form>
                  {/* Heading */}
                  <div className="flex flex-col-reverse lg:flex-row gap-4 justify-between">
                    <div>
                      <h3 className="text-lg font-medium">Tarifa</h3>
                      <p className="text-sm text-gray-500">
                        Selecciona la tarifa del usuario
                      </p>
                    </div>
                    <div className="flex gap-6">
                      <AppButton
                        title="Cancelar"
                        color="black"
                        bgColor="white"
                        onClick={() => setValue("1")}
                      />
                      <AppButton
                        title="Guardar"
                        type="submit"
                        disabled={!formik.isValid}
                      />
                    </div>
                  </div>
                  <hr className="my-5 " />

                  {/* Enrollment Date */}
                  <div className="flex flex-col lg:flex-row gap-4">
                    <label className="text-sm w-full lg:w-1/4 font-medium">
                      Fecha de inscripción
                    </label>
                    <div className="flex-1">
                      <FormikDatePicker
                        formik={formik}
                        label="Select Date"
                        name="enrollmentDate"
                      />
                    </div>
                  </div>

                  {/* Tution Fee */}

                  <div className="flex flex-col lg:flex-row gap-4 mt-10">
                    <div className="w-full lg:w-1/4 flex flex-col">
                      <label className="text-sm font-medium">
                        Cobro de mátricula
                      </label>
                      <label className="text-sm text-gray-500">
                        Elige si quieres cobrar mátricula en el primer pago
                      </label>
                    </div>
                    <div className="flex-1">
                      <CheckboxCard
                        key={tutionRate._id}
                        pack={tutionRate}
                        checked={tution}
                        readOnly={true}
                        handleOnChecked={handleTution}
                      />
                    </div>
                  </div>

                  <hr className="my-5 " />

                  {/* Reduced Rate Fee */}
                  {reducedRate && (
                    <div>
                      {/* Rate Date */}
                      <div className="flex flex-col lg:flex-row gap-4 mb-10">
                        <label className="text-sm w-full lg:w-1/4 font-medium">
                          Fecha de la tarifa
                        </label>
                        <div className="flex-1">
                          <FormikDatePicker
                            formik={formik}
                            label="Start Date"
                            name="secondRateStartDate"
                          />
                        </div>
                        <div className="flex-1">
                          <FormikDatePicker
                            formik={formik}
                            label="End Date"
                            name="secondRateEndDate"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:flex-row gap-4">
                        <div className="w-full lg:w-1/4 flex flex-col">
                          <label className="text-sm font-medium">
                            Seleccion de tarifa
                          </label>
                          <label className="text-sm text-gray-500">
                            Eliga la tarifa
                          </label>
                        </div>
                        <div className="flex-1" onChange={() => {}}>
                          <CheckboxCard
                            pack={reducedRate}
                            checked={true}
                            readOnly
                          />

                          {/* Remove Rate */}
                          <div className="flex justify-end p-5">
                            <div
                              className="flex gap-2 cursor-pointer items-center"
                              onClick={() => {
                                setReducedRate(null);
                                setSecondRateId("");
                              }}
                            >
                              <AiOutlineCloseCircle size={20} color="#ff0000" />
                              <p className="text-md text-red-500">
                                Eliminar tarifa
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {reducedRate && <hr className="my-5 " />}

                  {/* Rate Date */}
                  <div className="flex flex-col lg:flex-row gap-4 mb-10">
                    <label className="text-sm w-full lg:w-1/4 font-medium">
                      Fecha de la tarifa
                    </label>
                    <div className="flex-1">
                      <FormikDatePicker
                        formik={formik}
                        label={"Start Date"}
                        name="firstRateStartDate"
                      />
                    </div>

                    <div className="flex-1">
                      <FormikDatePicker
                        formik={formik}
                        label="End Date"
                        name="firstRateEndDate"
                      />
                    </div>
                  </div>

                  {/* Packages */}
                  <div className="flex flex-col lg:flex-row gap-4">
                    <div className="w-full lg:w-1/4 flex flex-col">
                      <label className="text-sm font-medium">
                        Seleccion de tarifa
                      </label>
                      <label className="text-sm text-gray-500">
                        Eliga la tarifa
                      </label>
                    </div>
                    <div
                      className="flex-1"
                      onChange={(e) =>
                        formik.setFieldValue("packageId", e.target.value)
                      }
                    >
                      {packages.map((pack) => (
                        <RadioCard
                          key={pack._id}
                          readOnly
                          pack={pack}
                          checked={formik.values.packageId === pack._id}
                        />
                      ))}

                      <div className="text-sm text-red-500">
                        {formik.errors.packageId}
                      </div>
                    </div>
                  </div>

                  {/* Add Rate */}
                  {reducedRate === null && (
                    <div className="flex justify-end p-5">
                      <div
                        className="flex gap-2 cursor-pointer items-center"
                        onClick={() => {
                          let p = packages.filter(
                            (pack) => pack._id === formik.values.packageId
                          );
                          if (formik.values.packageId) {
                            setSecondRateId(p[0]._id);
                            setReducedRate({ ...p[0], _id: "123456" });
                            formik.setFieldValue(
                              "secondRateStartDate",
                              formik.values.firstRateStartDate
                            );
                            formik.setFieldValue(
                              "secondRateEndDate",
                              formik.values.firstRateEndDate
                            );
                            formik.setFieldValue("packageId", "");
                            window.scrollTo(0, 0);
                          }
                        }}
                      >
                        <IoIosAddCircleOutline size={20} color="#9a1af0" />
                        <p className="text-md text-purple-500">
                          Añadir otra tarifa
                        </p>
                      </div>
                    </div>
                  )}

                  <hr className="my-5 " />

                  {/* Buttons */}
                  <div className="flex justify-end flex-col lg:flex-row gap-4">
                    <div className="flex gap-6">
                      <AppButton
                        title="Cancelar"
                        color="black"
                        bgColor="white"
                        onClick={() => setValue("1")}
                      />
                      <AppButton
                        title="Guardar"
                        type="submit"
                        disabled={!formik.isValid}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </TabPanel>

          {/* Third Step */}
          <TabPanel value="3">
            <Formik
              initialValues={{
                accountNo: "",
                BIC: "",
              }}
              validationSchema={validateThird}
              onSubmit={(values) => {
                setNewUser({ ...newUser, ...values });
                // console.log(newUser);
                setAlertVisible(true);
                // handleSubmit(values);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    {/* Heading */}
                    <div className="flex flex-col-reverse lg:flex-row gap-4 justify-between">
                      <div>
                        <h3 className="text-lg font-medium">
                          Información de facturación
                        </h3>
                        <p className="text-sm text-gray-500">
                          Añade el numero de cuenta del usuario
                        </p>
                      </div>
                      <div className="flex gap-6">
                        <AppButton
                          title="Cancelar"
                          color="black"
                          bgColor="white"
                          onClick={() => setValue("2")}
                        />
                        <AppButton title="Guardar" type="submit" />
                      </div>
                    </div>
                    <hr className="my-5 " />

                    {/* Account No */}
                    <div className="flex flex-col lg:flex-row gap-4">
                      <label className="text-sm w-full lg:w-1/4 font-medium">
                        Número de cuenta{" "}
                      </label>
                      <div className="flex-1">
                        <FormikInput
                          name="accountNo"
                          placeholder="Número de cuenta"
                          Icon={FiCreditCard}
                        />
                      </div>
                    </div>
                    <hr className="my-5 " />

                    {/* Swift Code / BIC */}
                    <div className="flex flex-col lg:flex-row gap-4">
                      <label className="text-sm w-full lg:w-1/4 font-medium">
                        Código SWIFT/BIC
                      </label>
                      <div className="flex-1">
                        <FormikInput
                          name="BIC"
                          placeholder="Código SWIFT/BIC"
                        />
                      </div>
                      <AppButton
                        title="Calcular BIC"
                        color="white"
                        bgColor="black"
                        onClick={() => {
                          calculateBIC(formik);
                        }}
                      />
                    </div>
                    <hr className="my-5 " />

                    {/* Terms and Conditions */}

                    <div className="flex flex-col lg:flex-row gap-4 mt-10">
                      <div className="w-full lg:w-1/4 flex flex-col">
                        <label className="text-sm font-medium">
                          Terminos y condiciones
                        </label>
                        <label className="text-sm text-gray-500">
                          Revisa y acepta los términos
                        </label>
                      </div>
                      <div className="flex-1">
                        <SimpleCheckbox
                          name="terms"
                          value="1"
                          title="Pagos"
                          desc="Acepto el cobro de los recibos según mi tarifa mediante domiciliación bancaria."
                        />
                        <SimpleCheckbox
                          name="terms"
                          value="2"
                          title="Normas"
                          desc="He leido y acepto las normas del centro. Con la norma principal: Cambios de tarifa y solicitud de baja antes del día 20 de cada mes"
                        />
                        <SimpleCheckbox
                          name="terms"
                          value="3"
                          title="Derechos de imagen"
                          desc="He leido y acepto la cesión de imágenes para redes sociales."
                        />
                      </div>
                    </div>

                    <hr className="my-5 " />

                    {/* Buttons */}
                    <div className="flex justify-end flex-col lg:flex-row gap-4">
                      <div className="flex gap-6">
                        <AppButton
                          title="Cancelar"
                          color="black"
                          bgColor="white"
                          onClick={() => {
                            setValue("2");
                          }}
                        />
                        <AppButton title="Guardar" type="submit" />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </TabPanel>
        </TabContext>
      </div>
    </Layout>
  );
};

export default AddUser;
