import moment from "moment";
import { getColors } from "../../utils/helper";

export const userColumns = [
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    minWidth: 250,
    renderCell: (params) => {
      return (
        <div className="flex items-center">
          <div className="w-10 mr-2">
            {params.row.img ? (
              <img
                className="w-10 h-10 rounded-full object-cover"
                src={params.row.img}
                alt=""
              />
            ) : (
              <div className="w-10 h-10 rounded-full flex justify-center items-center bg-purple-100 font-medium text-purple-600">
                {params.row.fullName[0]}
              </div>
            )}
          </div>
          <div className="flex flex-col flex-auto">
            <span className="text-sm font-medium text-black">
              {params.row.fullName}
            </span>
            <span className="text-sm font-normal text-gray-500">
              {params.row.email}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    field: "rol",
    headerName: "Rol",
    minWidth: 120,
    renderCell: (params) => {
      return (
        <div className="flex items-center">
          {params.row.isAdmin ? (
            <div className="text-gray-500 text-sm font-normal leading-tight">
              Admin
            </div>
          ) : (
            <div className="text-gray-500 text-sm font-normal leading-tight">
              Edit
            </div>
          )}
        </div>
      );
    },
  }

];

//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://i.pravatar.cc/150?img=3",
    condition: "Borrador",
    enrollmentDate: "19/09/2019",
    rate: "Grupos 8",
  },
  {
    id: 2,
    username: "Snow",
    img: "https://i.pravatar.cc/150?img=63",
    condition: "Inactivo",
    enrollmentDate: "19/09/2019",
    rate: "Grupos 12",
  },
];
