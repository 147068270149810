import React from "react";
import AppButton from "../../components/common/AppButton";
import Icon from "../../components/common/Icon";
import Input from "../../components/common/Input";
import BackToLogin from "../../components/form/BackToLogin";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { forgetPassword } from "../../services/userService";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  const sendMail = async (email) => {
    try {
      await forgetPassword(email);
      navigate("/email-sent", { replace: true });
    } catch (ex) {
      alert(ex.response.data);
    }
  };
  return (
    <div className="flex flex-col h-full w-full items-center mt-24">
      <div className="flex flex-col w-4/5 items-center mx-4 md:w-96">
        <Icon darkColor="#FEE4E2" lightColor="#FEF3F2" />
        <h1 className="text-3xl mt-8 text-center">Recupera tu contraseña</h1>
        <p className="text-gray-400 mt-3 text-center">
          No te preocupes te enviamos un email para recuperarla
        </p>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validate}
          onSubmit={(values) => {
            const { email } = values;
            sendMail(email);
          }}
        >
          {(formik) => (
            <Form action="" className="form-vertical form w-full mt-8">
              <Input name="email" label="Email" type="email" />
              <AppButton
                type="submit"
                title="Resetear contraseña"
                extraClasses="mt-6"
                disabled={!formik.isValid}
              />
            </Form>
          )}
        </Formik>
        <Link to="/login" className="mt-8">
          <BackToLogin />
        </Link>
      </div>
    </div>
  );
};

export default ForgetPassword;
