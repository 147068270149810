import RoundedIcon from "../../components/form/RoundedIcon";
import { AiOutlineFile } from "react-icons/ai";

export const paymentsColumns = [
  // { field: "id", headerName: "ID", width: 30 },
  {
    field: "nameOfRemittance",
    headerName: "Remesa",
    flex: 1,
    minWidth: 280,
    renderCell: (params) => {
      return (
        <div className="flex items-center gap-2">
          <RoundedIcon Icon={AiOutlineFile} />
          <span className="text-sm font-medium text-black">
            {params.row.nameOfRemittance}
          </span>
        </div>
      );
    },
  },
  {
    field: "condition",
    headerName: "Estado",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      let color = "text-gray-500";
      let bgColor = "bg-gray-100";
      let indicatorColor = "bg-green-500";
      switch (params.row.condition) {
        case "Pendiente":
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
          break;

        case "Pagada":
          color = "text-green-500";
          bgColor = "bg-green-100";
          indicatorColor = "bg-green-500";
          break;
        default:
          color = "text-gray-500";
          bgColor = "bg-gray-100";
          indicatorColor = "bg-gray-500";
      }
      return (
        <div
          className={`${bgColor} ${color} px-2 py-1 text-xs rounded-full flex justify-center items-center`}
        >
          <div
            className={`w-1.5 h-1.5 rounded-full mr-2 ${indicatorColor} `}
          ></div>
          {params.row.condition}
        </div>
      );
    },
  },
  {
    field: "paymentDate",
    headerName: "Fecha de cobro",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "accountNo",
    headerName: "Cuenta de destino",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      return (
        <div className="flex items-center">
          <img
            className="w-8"
            src={
              "https://images.assetsdelivery.com/compings_v2/oksananahorna/oksananahorna1803/oksananahorna180300197.jpg"
            }
          />
          {params.row.accountNo}
        </div>
      );
    },
  },
  {
    field: "rate",
    headerName: "Precio",
    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      return <div className={`text-gray-500`}>{params.row.rate}</div>;
    },
  },
];

//temporary data
export const billingRows = [
  {
    id: 1,
    nameOfRemittance: "Diciembre_2021",
    condition: "Pendiente",
    paymentDate: "19/09/2019",
    accountNo: "...7687",
    rate: "98",
  },
];
